import React, { useState, useEffect } from 'react';

import { store } from 'store/index';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function CheckInternetTimer(props) {

	const [internet, setInternet] = useState(true);

	const Checking = () => {
		let connection;
		if(typeof store.getState().intStatus === undefined || typeof store.getState().intStatus === "undefined") {
			connection = true;
		} else {
			connection = store.getState().intStatus;
		}
		if (connection) {
			if(!internet) {
				setInternet(true);
			}
		} else {
			if(internet) {
				setInternet(false);
			}
		}
	}

	useEffect(() => {
		setInterval(() => Checking(), 1000);
	}, []);

	return "";
}