import React, { useState, useEffect } from 'react';

export default function Timer(props) {
	const [secondCount, setSecondCount] = useState(props.secondCount);
	const [stopTimer, setStopTimer] = useState(props.stopTimer);
	const [i_id, setI_id] = useState(null);



	const initTimer = () => {
		const interval_id = setInterval(() => {
			setSecondCount(secondCount => secondCount + 1);
		}, 1000);
		props.setIntervalId(interval_id);
		setI_id(interval_id);
	}


	/**
	 * didMount.
	 */
	useEffect(() => {
		initTimer();
	}, []);


	/**
	 * Restart Timer.
	 */
	useEffect(() => {
		if (props.restart === true) {
			setSecondCount(secondCount => 0);
			props.setRestart();
			
			// when user click on submit. and then click on restart button.
			// this works in that case.
			if (i_id === null) {
				initTimer();
			}
		}		
	}, [props.restart]);


	/**
	 * Stop Timer. 
	 * On submit button clicked.
	 */
	useEffect(() => {
		if (props.stopTimer === true) {
			props.setSecondCount(secondCount);
			props.setStopTimer();
			if (i_id !== null) {
				props.setIntervalId(i_id);
				clearInterval(i_id);
				setI_id(null);
			}
		}
	}, [props.stopTimer]);


	/**
	 * Open New Question.
	 * On Next/Previous/Selection Clicked.
	 */
	useEffect(() => {
		if (props.newQuestion === true) {
			setSecondCount(secondCount => 0);
			props.setNewQuestion();

			if (i_id !== null) {
				clearInterval(i_id);
			}
			initTimer();
		}
	}, [props.newQuestion])



	/**
	 * return.
	 */
	return (
		<div className="timeRemaining">
			<p>
				<b>
					<i className="fa fa-clock-o"></i>
					<span>Time spent: {Math.floor(secondCount / 60) +':'+ (secondCount % 60)}</span>
				</b>
			</p>
		</div>
	);
}