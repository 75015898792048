import React, { useRef, useEffect } from 'react';
import API from 'api.js';

export default function StayConnected(props) {

	let interval_id = useRef(null);

	useEffect(() => {
		clearInterval(interval_id.current); // to make sure, timer doesn't start multiple times.
		start();

		return () => {
			clearInterval(interval_id.current);
		};
	}, []);


	const start = () => {
		interval_id.current = setInterval(() => {
			fetch('https://la-ping.languageacademy.com.au/connected')
			.then(response => response.json())
			.then(json => console.log(""))
			.catch(error => console.log(""));
		}, 5000);
	}

	return "";
}