import React, { useState, useEffect } from 'react';
import { s3base } from 'api.js';
import {
	Button,
	Progress
} from 'reactstrap';
import Sound from 'react-sound';

export default function RecordinBox({
	recordingWait,
	recordingStartsIn,
	listening,
	listening_finish,
	updateListeningState,
	waiting
}) {

	const WAITING = 0;
	const STARTED = 1;
	const FINISHED = 2;
	const [start, setStart] = useState(false);
	const [seconds, setSeconds] = useState(null);
	const [intervalId, setIntervalId] = useState(null);
	const [recordingStatus, setRecordingStatus] = useState(WAITING);
	const [recordSeconds, setRecordSeconds] = useState(0);
	const [recordTimerId, setRecordTimerId] = useState(null);
	const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED)


	useEffect(() => {
		if (recordingStartsIn > 0) {
			clearInterval(intervalId); // timer ke multiple instance create na ho jaye iske liye.
			setSeconds(recordingStartsIn);
			setStart(true);
		}
	}, [recordingStartsIn]);


	useEffect(() => {
		if (!waiting && start) { // start tabhi krna hai jab waiting false ho.
			setStart(false);
			const id = setInterval(() => {
				setSeconds(seconds => (seconds - 1));
			}, 1000);
			setIntervalId(id);
		}
	}, [start, waiting]);


	useEffect(() => {
		if (seconds === 0) {
			clearInterval(intervalId);
			setPlayStatus(Sound.status.PLAYING);
			// setRecordingStatus(STARTED);
			// startRecording();
		}
	}, [seconds]);


	useEffect(() => {
		if (recordSeconds === 40) {
			stopRecording();
		}
	}, [recordSeconds]);


	const startRecording = () => {
		updateListeningState(listening);
		startRecordingTimer();
	}


	const startRecordingTimer = () => {
		const id = setInterval(() => {
			setRecordSeconds(recordSeconds => (recordSeconds + 1));
		}, 1000);
		setRecordTimerId(id);
	}


	const currentStatus = () => {
		if (!waiting && seconds !== null) {
			if (recordingStatus === WAITING) {
				return `Recording starts in ${seconds} seconds`;
			} else if (recordingStatus === STARTED) {
				return "Recording started";
			}
		}
		return "";
	}


	const stopRecording = () => {
		setTimeout(() => {
			updateListeningState(listening_finish);
		}, 1000);
		clearInterval(recordTimerId);
		setRecordSeconds(41);
		setRecordingStatus(FINISHED);
	}

	const beepFinished = () => {
		setPlayStatus(Sound.status.STOPPED);
		setTimeout(() => {
			setRecordingStatus(STARTED);
			startRecording();
		}, 200);
	}

	return (
		<>
			<div className="audio-box">
				<h4>Recorded Answer</h4>
				<p>
					<strong>Current status:</strong>
				</p>
				<p>{currentStatus()}</p>
				<hr></hr>

				<div className="stop-btn">
					{recordingStatus === STARTED &&
						<Button color="success" onClick={stopRecording} size="sm">
							Stop
						</Button>
					}
				</div>
				<hr></hr>

				<Progress
					color="info"
					value={recordSeconds * 2.5}
				/>
			</div>
			<Sound
				url={`${s3base}/ptedata/ptemedia/beep.mp3`}
				playStatus={playStatus}
				onFinishedPlaying={beepFinished}
				autoLoad={true}
			/>
		</>
	);
}