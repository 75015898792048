import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import API from "api.js";
import Cookies from "universal-cookie";
import { Row, Col } from "react-bootstrap";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  NavLink as NavLink1,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  CardText,
  UncontrolledTooltip,
} from "reactstrap";
//import { Input } from "reactstrap";
import { Link } from "react-router-dom";
import { store, persistor } from "store/index";
import moment from "moment";
import { setAlert } from "action/index.js";
import { setUser } from "action/index.js";
import Slider from "react-slick";
import { colors } from "@material-ui/core";
import SimpleReactValidator from "simple-react-validator";
const cookies = new Cookies();

export default function NewDashboard() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [practicedQuestions, setPracticedQuestions] = useState(0);
  const [practiceDays, setPracticeDays] = useState(0);
  const [anySub, setAnySub] = useState(0);
  const [showDiscountPopup, setShowDiscountPopup] = useState(false);
  const [announceModal, setAnnounceModal] = useState(false);
  const [data, setData] = useState(false);
  const [dashboardData, setDashboardData] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [targetModal, setTargetModal] = useState(false);
  const [examModal, setExamModal] = useState(false);
  const [examDate, setExamDate] = useState(user.exam_date || "");
  const [speaking, setSpeaking] = useState(user.speaking || "");
  const [reading, setReading] = useState(user.reading || "");
  const [writing, setWriting] = useState(user.writing || "");
  const exam_date = examDate !== "undefined" ? examDate : "";
  const [errorBag, setErrorBag] = useState(null);
  // const [listening, setListening] = useState(user.listening || "");
  // const overall_score =
  //   (user.speaking + user.reading + user.writing + user.listening) / 4;

  const [diffDays, setDiffDays] = useState(0);
  const [firstName, setFirstName] = useState(user.first_name || "");
  const [lastName, setLastName] = useState(user.last_name || "");
  const [listening, setListening] = useState(user.listening || "");
  const overall_score =
    (user.speaking + user.reading + user.writing + user.listening) / 4;
  const [validator] = useState(new SimpleReactValidator());

  const checkVideoSubscription = () => {
    if (
      false &&
      store.getState().user.active_subscription.length > 0 &&
      store.getState().user.active_subscription[0].package_id === 9
    ) {
      setModal(true);
    }
  };
  const { inst_video } = dashboardData;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      inst_video === undefined
        ? 0
        : inst_video.length > 4
        ? 4
        : inst_video.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        checkVideoSubscription();

        if (
          !user.institute &&
          (!user.score ||
            user.score === "null" ||
            !user.lang ||
            !user.phone ||
            !user.email ||
            !user.country_residence ||
            !user.dob ||
            !user.country_citizenship)
        ) {
          setModal(true);
        }

        const dashboardResponse = await API.get("get_dashboard_data");
        const dashboardData = dashboardResponse.data.data;

        setDashboardData(dashboardData);
        setPracticedQuestions(
          dashboardData.practice_history
            ? dashboardData.practice_history.questions
            : 0
        );
        setPracticeDays(
          dashboardData.practice_history
            ? dashboardData.practice_history.days
            : 0
        );

        const additionalDataResponse = await API.get("dashboard/data");
        const additionalData = additionalDataResponse.data.data;
        setAnySub(additionalData.anysub);
        setData(additionalData);
        if (user.type === 1 || user.type === 2) {
          if (anySub !== 1 && user.popup_query !== 1) {
            setShowDiscountPopup(true);
          }
        }

        let tempAnnounceModal =
          additionalData.announcement1 &&
          additionalData.announcement1.length > 0;
        let tempExamModal = false;
        if (
          user.institute &&
          !cookies.get("exam-date") &&
          (!user.exam_date || diffDays < 0)
        ) {
          cookies.set("exam-date", "1", { path: "/" });
          tempExamModal = true;
        }

        setAnnounceModal(tempAnnounceModal);
        setExamModal(tempExamModal);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [user, diffDays, anySub]);

  useEffect(() => {
    const computeDiffDays = () => {
      const userExamDate =
        user.exam_date && user.exam_date !== "undefined"
          ? user.exam_date
          : null;
      if (userExamDate) {
        const startDate = moment(new Date()).format("YYYY-MM-DD");
        const endDate = moment(userExamDate, "YYYY-MM-DD");
        const result = endDate.diff(startDate, "days");
        setDiffDays(result);
      }
    };

    computeDiffDays();
  }, [user.exam_date]);

  const saveTargetDate = async (e) => {
    e.preventDefault();
    const bodyFormData = new FormData();
    bodyFormData.set("exam_date", examDate);

    try {
      const response = await API({
        method: "POST",
        url: "/updateTarget",
        data: bodyFormData,
      });

      if (response.status === 200) {
        dispatch(setUser(response.data.response.user));
        const userExamDate =
          response.data.response.user.exam_date !== "undefined"
            ? response.data.response.user.exam_date
            : "";
        const startDate = moment(new Date()).format("YYYY-MM-DD");
        const endDate = moment(userExamDate, "YYYY-MM-DD");
        const result = endDate.diff(startDate, "days");

        setDiffDays(result);
        setExamModal(false);
        setTargetModal(false);
        dispatch(
          setAlert({
            flag: true,
            type: 1,
            message: response.data.response.message,
          })
        );
        setErrorBag(""); // Clear any previous errors
      } else {
        setErrorBag("Unexpected response status: " + response.status);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        const formattedErrors = Object.entries(errors)
          .map(([key, value]) => `${key}: ${value.join(", ")}`)
          .join("\n");
        setErrorBag(formattedErrors);
      } else {
        setErrorBag("An unexpected error occurred.");
      }
    }
  };

  const targetToggle = () => {
    setTargetModal(!targetModal);
  };

  const saveTarget = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      setTargetModal(true); // Open the target modal if there are validation errors
      return;
    }

    setErrorBag(null); // Clear any previous errors

    const bodyFormData = new FormData();
    bodyFormData.set("speaking", speaking);
    bodyFormData.set("listening", listening);
    bodyFormData.set("reading", reading);
    bodyFormData.set("writing", writing);

    try {
      const response = await API({
        method: "POST",
        url: "/updateTarget",
        data: bodyFormData,
      });

      if (response.status === 200) {
        dispatch(setUser(response.data.response.user));
        // Update user state and other necessary states
        setExamModal(false);
        setTargetModal(false);
        const newUser = response.data.response.user;
        setUser(newUser);
        //setOverallScore((newUser.speaking + newUser.reading + newUser.writing + newUser.listening) / 4);
        dispatch(
          setAlert({
            flag: true,
            type: 1,
            message: response.data.response.message,
          })
        );
      } else if (response.status === 422) {
        // Handle validation errors
        setErrorBag(
          Object.values(response.data.errors).map((error) => error.join("\n"))
        );
      } else {
        setErrorBag("Unexpected response status: " + response.status);
      }
    } catch (error) {
      setErrorBag("An unexpected error occurred.");
    }
  };

  const changeInput = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "speaking":
        setSpeaking(value);
        break;
      case "writing":
        setWriting(value);
        break;
      case "reading":
        setReading(value);
        break;
      case "listening":
        setListening(value);
        break;
      default:
        break;
    }
    validator.showMessages();
  };

  const examToggle = () => {
    setExamModal(!examModal);
  };

  return (
    <>
      <div
        className="la-free-mt-app-testimonial-section py-40 free-mock-test-page"
        id="la-free-mt-app-testimonial-section"
      >
        <div className="upperCard">
          <div className="testAttempts mt-5">
            <div className="row">
              <div className="col">
                <div className="testRecord speaking">
                  <div className="icon-wrapper">
                    <i class="fa fa-comments-o" aria-hidden="true"></i>
                    {/* Icon on the left */}
                  </div>
                  <div className="text-content">
                    <h4>Speaking</h4>
                    <span className="doneQues">
                      Done {data && data.speak_a} Questions
                    </span>
                    <span className="TotalQues">
                      Total {data && data.speak_t} Questions
                    </span>
                    {/* <Link to="/admin/progress/1?mock=0">View Analysis</Link> */}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="testRecord writing">
                  <div className="icon-wrapper">
                    <i class="fa fa-pencil" aria-hidden="true"></i>{" "}
                    {/* Icon on the left */}
                  </div>
                  <div className="text-content">
                    <h4>Writing</h4>
                    <span className="doneQues">
                      Done {data && data.write_a} Questions
                    </span>
                    <span className="TotalQues">
                      Total {data && data.write_t} Questions
                    </span>
                    {/* <Link to="/admin/progress/2?mock=0">View Analysis</Link> */}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="testRecord reading">
                  <div className="icon-wrapper">
                    <i class="fa fa-book" aria-hidden="true"></i>{" "}
                    {/* Icon on the left */}
                  </div>
                  <div className="text-content">
                    <h4>Reading</h4>
                    <span className="doneQues">
                      Done {data && data.read_a} Questions
                    </span>
                    <span className="TotalQues">
                      Total {data && data.read_t} Questions
                    </span>
                    {/* <Link to="/admin/progress/3?mock=0">View Analysis</Link> */}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="testRecord listening">
                  <div className="icon-wrapper">
                    <i class="fa fa-headphones" aria-hidden="true"></i>{" "}
                    {/* Icon on the left */}
                  </div>
                  <div className="text-content">
                    <h4>Listening</h4>
                    <span className="doneQues">
                      Done {data && data.listen_a} Questions
                    </span>
                    <span className="TotalQues">
                      Total {data && data.listen_t} Questions
                    </span>
                    {/* <Link to="/admin/progress/4?mock=0">View Analysis</Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="studyTargetOuter">
          <Col className="mainCard1" md="5" sm="5">
            <h3 className="cardTitle">
              <span>Study Stats</span>
            </h3>
            <Row xs={1} md={2}>
              <Col className="userRecordLeft">
                <div className="userRecordLeftInr">
                  {" "}
                  <text>Total Mock Practiced </text>
                  <h5 style={{ color: "white" }}> {data && data.mock}</h5>
                </div>
              </Col>
              <Col className="userRecordLeft userRecordRight">
                <div className="userRecordLeftInr userRecordRightInr">
                  <text>Total Question Practiced</text>
                  <h5>{practicedQuestions}</h5>
                </div>
              </Col>
              <Col className="userRecordLeft">
                <div className="userRecordLeftInr">
                  {" "}
                  <text>Prac. Days </text>
                  <h5>{practiceDays}</h5>
                </div>
              </Col>
              <Col className="userRecordLeft userRecordRight">
                <div className="userRecordLeftInr userRecordRightInr">
                  <Link to="/admin/practice-detail" className="d-block">
                    See History{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                  <a href="javascript:void()" onClick={examToggle}>
                    Set Exam date{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md="7" sm="7">
            <h3 className="cardTitle">
              <span>Study Stats</span>
            </h3>
            <Col className="mainCard2">
              <Row xs={1} md={4}>
                <Col className="card1">
                  <text>Overall </text>
                  <h4>{overall_score}</h4>
                </Col>
                <Col className="courseTarget">
                  {" "}
                  <Row className="userRecord" xs={1} md={2}>
                    <Col>
                      Listening <text> {listening} </text>
                    </Col>
                    <Col>
                      Reading <text>{reading}</text>
                    </Col>
                    <Col>
                      Speaking <text>{speaking}</text>
                    </Col>
                    <Col>
                      Writing <text>{writing}</text>
                    </Col>
                  </Row>{" "}
                </Col>
                <Col className="setTarget">
                  <a href="#" onClick={targetToggle}>
                    Set Target{" "}
                  </a>
                </Col>
                <Col className="card4">
                  {" "}
                  <div className="card4ExamDate">
                    Exam Date: <span>{examDate}</span>
                  </div>
                  <div className="card4DaysLeft">
                    Days left{" "}
                    <span>
                      {isNaN(diffDays) ? "-" : diffDays > 0 ? diffDays : 0}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>

        <div className="instVidsOuter">
          {inst_video !== undefined && (
            <div className="row la-free-mt-app-testimonial-mrow">
              <div className="col-lg-12 la-free-mt-app-testimonial-mcol">
                <h3 className="cardTitle">
                  <span>INSTITUTE VIDEOS</span>
                </h3>
                <div className="testimonial-carousal">
                  <div className="row">
                    {inst_video.map((val, i) => (
                      <div className="col-lg-6" key={i}>
                        <div className="single-testimonial la-video-testimonial">
                          <iframe
                            className="col-lg-12"
                            height="180"
                            src={val.video}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={targetModal} size="lg" toggle={targetToggle}>
        <div className="modalHeader">
          <h2>Set Target</h2>
        </div>
        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label>Speaking Score</label>
                <Input
                  defaultValue={speaking}
                  name="speaking"
                  placeholder="Speaking Score"
                  type="text"
                  onChange={changeInput}
                  onBlur={() => validator.showMessageFor("speaking")}
                />
                {validator.message(
                  "speaking",
                  speaking,
                  "required|numeric|min:0,num|max:90,num"
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label>Writing Score</label>
                <Input
                  defaultValue={writing}
                  name="writing"
                  placeholder="Writing Score"
                  type="text"
                  onChange={changeInput}
                  onBlur={() => validator.showMessageFor("writing")}
                />
                {validator.message(
                  "writing",
                  writing,
                  "required|numeric|min:0,num|max:90,num"
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label>Reading Score</label>
                <Input
                  defaultValue={reading}
                  name="reading"
                  placeholder="Reading Score"
                  type="text"
                  onChange={changeInput}
                  onBlur={() => validator.showMessageFor("reading")}
                />
                {validator.message(
                  "reading",
                  reading,
                  "required|numeric|min:0,num|max:90,num"
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label>Listening Score</label>
                <Input
                  defaultValue={listening}
                  name="listening"
                  placeholder="Listening Score"
                  type="text"
                  onChange={changeInput}
                  onBlur={() => validator.showMessageFor("listening")}
                />
                {validator.message(
                  "listening",
                  listening,
                  "required|numeric|min:0,num|max:90,num"
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            className="modalCloseBtn"
            onClick={saveTarget}
          >
            Save
          </Button>
          <Button
            color="secondary"
            className="modalCloseBtn"
            onClick={targetToggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {examModal && (
        <Modal isOpen={examModal} size="md">
          <div className="modalHeader">
            <h2>Set Exam Date</h2>
          </div>
          <ModalBody>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <label>Exam Date</label>
                  <Input
                    defaultValue={examDate}
                    name="exam_date"
                    placeholder="Exam date (YYYY-MM-DD)"
                    type="date"
                    onChange={(e) => setExamDate(e.target.value)}
                  />
                  {/* Validation message */}
                  <div className="srv-validation-message">
                    {errorBag && <div>{errorBag}</div>}
                    {validator.message("exam date", examDate, "required")}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="modalCloseBtn"
              onClick={saveTargetDate}
            >
              Save
            </Button>
            <Button
              color="secondary"
              className="modalCloseBtn"
              onClick={examToggle}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
