const reducer = (state = {token:null,user:null,loaded:true,subscribed:false,is_main:true,is_portal:true,coreSub: 0,logo:"/images/logol.png",alert:{'flag':false,'type':1,'message':'testing'}}, action) => {
   switch (action.type) {
      case 'SETTOKEN': return {...state,token:action.val};
      case 'SETLOADER': return {...state,loaded:action.val};
      case 'SETUSER': return {...state,user:action.val};
      case 'SETSUB': return {...state,subscribed:action.val};
      case 'SETALERT': return {...state,alert:action.val};
      case 'SETLOGO': return {...state,logo:action.val};
      case 'SETISMAIN': return {...state,is_main:action.val};
      case 'ISPORTAL': return {...state,is_portal:action.val};
      case "INTSTATUS": return { ...state, intStatus: action.val };
      case "CORESUB": return {...state, coreSub: action.val}
      default: return state
   }
}
export default reducer;