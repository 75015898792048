import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";
import cookie from 'react-cookies'
import { connect } from 'react-redux';
import "components/custom.css";
import API from "api.js";
import { Redirect, NavLink,Link } from "react-router-dom";
import { setToken, setUser, setLogo, setSub} from 'action/index.js';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (user) => dispatch(setUser(user)),
      setSub: (sub) => dispatch(setSub(sub)),
      setLogo: (sub) => dispatch(setLogo(sub))
   };
};

class Login extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      login:false,
      error:null,
      error_bag:null,
      loginLock:false,
      logoIcon:"/images/logol.png",
      isMainSite: true
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    ReactGA.initialize('UA-157984080-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init('197333187452630');
    ReactPixel.pageView();
    document.body.classList.toggle("login-page");
    this.props.setToken(null);
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  postLogIn = async() => {
    if (this.state.loginLock) {
      return ;
    }
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
    }

    this.setState({error_bag:null,loginLock:true});
    
    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email);
    bodyFormData.set('password', this.state.password);
    bodyFormData.set('subdomain', window.location.hostname.split('.').shift());
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/login',
        data: bodyFormData
      })
    }catch(error) {

        
    };
    
    if (res.status === 200) {
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60);
      this.props.setToken(res.data.access_token);
      this.props.setUser(res.data.user);
      this.props.setLogo(res.data.image);
      let sub  = (res.data.user.active_subscription.length === 0)?false:true;
      this.props.setSub(sub);
      if (res.status === 200) {
        window.location.href  = "/admin/dashboard";
        //this.setState({login:true,loginLock:false});     
      }
      
    }else if(res.response.status === 422){
      this.setState({error:res.response });
      let str = '';
      
      Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:str,loginLock:false});

    }
    else if (res.response.status === 402) {
      this.setState({error_bag:"Wrong credentials.",loginLock:false});
    }
    
  }

  submitForm = (e) => {
    e.preventDefault();
    this.postLogIn();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };
  render() {
    const {token,setToken} = this.props;
    if (this.state.login) {
        //return (<Redirect to="/admin/dashboard" />);
    }
    return (
      <div className="innerPage">
      <div className="login-page">
      <Helmet htmlAttributes>
        <title>Login - 20 Task</title>
        <meta name="twitter:title" content="Login - 20 Task" />
      <meta property="og:title" content="Login - 20 Task" />
      <meta name="description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />      
        
        <meta name="twitter:description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />
        <meta property="og:description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />
    </Helmet>
      <section class="bannerOuter">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1>Login</h1>
        
      </div>
    </div>
  </div>
</section>
<section class="contentOuter">
  <form onSubmit={this.submitForm}>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 loginForm">
        {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
          <div class="fieldOuter">
            <Input 
                placeholder="Email"
                type="text"
                name="email"
                value={this.state.email}
                onChange={(e) => { this.changeInput(e)} }
              />
              {this.validator.message('Email', this.state.email, 'required|email')}
          </div>
          <div class="fieldOuter">
            <Input
                placeholder="Password"
                type="password"
                name="password"
                value={this.state.password}
                onChange={(e) => { this.changeInput(e)} }
                autoComplete="off"
              />
              {this.validator.message('Password', this.state.password, 'required')}
          </div>
          <div class="rememberForgot">
            <div class="rememberMe">
              <input type="checkbox" name="" />
              <span>Remember me</span>
            </div>
            <div class="forgotPwd">
              <NavLink className="mt-5" to='/auth/forgot'><span>Forgot your Password?</span></NavLink>
            </div>
          </div>
          <div class="fieldOuter text-right">
            <Button
              className="themeBtn"
              onClick={(e) => {this.postLogIn()}}
            >
              Login
            </Button>
          </div>
      </div>
    </div>
  </div>
  <input  type="submit" style={{display:"none"}} />
  </form>
</section>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`
          }}
        />
      </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
