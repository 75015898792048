import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button
} from "reactstrap";
import { Helmet } from "react-helmet";
import Switch from 'react-bootstrap-switch';
import API, { s3base } from "api.js";
import { store } from "store/index";
import { setAlert } from "action/index.js";
import { setUser } from 'action/index.js';
import PracticeHeader from "components/NewPractice/PracticeHeader";
import QuestionTestedButton from "components/NewPractice/QuestionTestedButton";
import QuestionReportButton from "components/NewPractice/QuestionReportButton";
import DiscussionArea from "components/NewPractice/DiscussionArea";
import PracticeResult from "components/NewPractice/PracticeResult";
import ReadAloud from "components/NewPractice/Speaking/ReadAloud";
import RepeatSentence from "components/NewPractice/Speaking/RepeatSentence";
import DescribeImage from "components/NewPractice/Speaking/DescribeImage";
import RetellLecture from "components/NewPractice/Speaking/RetellLecture";
import WordOptionsPopup from 'views/WordOptionsPopup';

const NewPractice = ({ ...props }) => {
  const user = useSelector((state) => state.user);
  const { id, aType, mark, attempted, complexity, index } = useParams();

  const QUESTION_STRATEGY_NORMAL = 1;

  const [microphoneAccess, setMicrophoneAccess] = useState(false);
  const [isChromeBrowser, setIsChromeBrowser] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(parseInt(index) || 0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [questionLoaded, setQuestionLoaded] = useState(false);
  const [isAttempted, setIsAttempted] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const [restart, setRestart] = useState(false);
  const [switched, setSwitched] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState((user.active_subscription.length > 0) ? true : false);
  const [processing, setProcessing] = useState(false);
  const [viewVideoEnglish, setViewVideoEnglish] = useState(false);
  const [viewVideoHindi, setViewVideoHindi] = useState(false);
  const [resultMode, setResultMode] = useState(false);
  const [submissionData, setSubmissionData] = useState(null);
  const [resultData, setResultData] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [questionStrategy, setQuestionStrategy] = useState(QUESTION_STRATEGY_NORMAL);
  const [wordOption, setWordOption] = useState(false);
  const [selectedWord, setSelectedWord] = useState("");

  const updateQuestionStrategy = (strategy) => {
    setQuestionStrategy(strategy);
  }

  const updateQuestionData = (updatedData) => {
    setQuestionData(updatedData);
  }

  const loadQuestion = async (ques, q_id = null) => {
    const localStorage_orderby = localStorage.getItem("orderby");
    if (!localStorage_orderby) {
      localStorage.setItem("orderby", "desc");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const hideNav = parseInt(urlParams.get("view")) === 1;
    const search_string = urlParams.get("search") || "";
    const orderby = localStorage.getItem("orderby") || "desc";

    const type = id;

    const searchParams = new URLSearchParams();
    searchParams.append("prediction", 0);
    searchParams.append("type", aType);
    searchParams.append("mark", mark);
    searchParams.append("attempted", attempted);
    searchParams.append("complexity", complexity);
    searchParams.append("orderby", orderby);
    searchParams.append("practice", !hideNav);
    searchParams.append("open_ques", ques);
    searchParams.append("qid", q_id);
    searchParams.append("search", search_string);

    // const url = `question/${type}?prediction=${0}&type=${aType}&mark=${mark}&attempted=${attempted}&complexity=${complexity}&orderby=${orderby}&practice=${!hideNav}&open_ques=${ques}&qid=${q_id}&search=${search_string}`;

    const res = await API({
      method: "GET",
      url: `question/${type}?${searchParams.toString()}`,
    });

    setHideNav(hideNav);
    return res;
  };

  const loadAttemptedQuestiondata = (currentQuestion) => {
    const { user_attempted_questions } = store.getState().user;
    
    let attemptedQuestions = [];
    if (user_attempted_questions !== null && user_attempted_questions !== undefined) {
      attemptedQuestions = user_attempted_questions.attempted_questions;

      let question_id = questionData[0].id;
      question_id = question_id.toString();
      
      const checkquestionid = attemptedQuestions.includes(question_id);
      if(checkquestionid) {
        setIsAttempted(true);
      } else {
        setIsAttempted(false);
      }
    }
  }

  const fetchQuestion = async (openQues) => {
    const q_id = null;
    const ques = await loadQuestion(openQues, q_id);
    if (ques.status === 200) {
      setCurrentQuestion(openQues);
      setQuestionData(ques.data.data);
      loadAttemptedQuestiondata();
      setQuestionLoaded(true);
    } else {
      store.dispatch(
        setAlert({
          flag: true,
          type: 2,
          message: "Something went wrong. Unable to load question.",
        })
      );
    }
  }

  const fetchData = async () => {
    const localStorage_orderby = localStorage.getItem("orderby");
    if (!localStorage_orderby) {
      localStorage.setItem("orderby", "desc");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const openQues = parseInt(urlParams.get("page")) || 0;
    const q_id = props.match ? props.match.params.index : null;
    
    const ques = await loadQuestion(openQues, q_id);
    if (ques.status === 200) {
      setTotalQuestions(ques.data.total);
      setCurrentQuestion(openQues);
      setQuestionData(ques.data.data);
      loadAttemptedQuestiondata();
      setQuestionLoaded(true);
    } else {
      store.dispatch(
        setAlert({
          flag: true,
          type: 2,
          message: "Something went wrong. Unable to load question.",
        })
      );
    }
  };


  /**
   * component onLoad.
   */
  useEffect(() => {
    checkBrowser();
    checkMicrophoneAccess();
  }, []);


  /**
   * requirements ok.
   */
  useEffect(() => {
    if (microphoneAccess && isChromeBrowser) {
      fetchData();
    }
  }, [microphoneAccess, isChromeBrowser]);

  const checkBrowser = () => {
    const chrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (chrome) setIsChromeBrowser(true);
  }

  const checkMicrophoneAccess = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => {
          setMicrophoneAccess(true);
        });
    } else {
      console.log("getUserMedia not supported on your browser!");
    }
  }

  const resetAnswerValues = () => {
    setResultMode(false);
    setSubmissionData(null);
    setResultData("");
    setHistoryData([]);
    setShowAnswer(false);
  }

  const restartQuestion = () => {
    setRestart(true);
    resetAnswerValues();

    // reset restart value after 500 ms.
    setTimeout(() => {
      setRestart(false);
    }, 1);
  }

  const restartButton = () => {
    return (
      <Button
        color="info"
        className="bottom-btn"
        onClick={restartQuestion}
        disabled={false}
        size="sm"
      >Restart</Button>
    );
  }

  const toggleSwitch = () => {
    setSwitched(!switched);
  }

  const audioTranscriptSwitch = () => {
    if ([2,4,5,13,14,15,16,17,18,19,20].includes(questionData[0].subcategory_id)) {
      return (
        <>
          <span className="audio_script_button">Audio Transcript : <Switch onText="Show" offText="Hide" onChange={toggleSwitch} on={switched}></Switch></span>
          {wordOption &&
            <WordOptionsPopup
              isOpen={wordOption}
              word={selectedWord}
              wordOptionAction={wordOptionPopup}
            />
          }
        </>
      );
    }
  }

  const aiTokenBadge = () => {
    if (false && !isSubscribed) {
      return (
        <span className="badge badge-primary">(A.I. Token(s) X {user.a_token})</span>
      );
    }
  }

  const showResponse = () => {
    setProcessing(true);

    const subcategory_id = parseInt(questionData[0].subcategory_id);
    let bodyFormData = {};
    if ([1, 2, 3, 4].includes(subcategory_id)) {
      bodyFormData = constructSubmissionData();
    }

    API({
      method: 'POST',
      url: 'check/answer1',
      data: bodyFormData
    }).then(data => {
      
      if (data.data.user) {
        store.dispatch(setUser(data.data.user));
        loadAttemptedQuestiondata();
      }

      setResultData(`Content Score : ${data.data.data.content} \n Fluency Score : ${data.data.data.fluency} \n Pronunciation Score : ${data.data.data.pronunciation} \n ${data.data.data.html}`);
      setHistoryData(data.data.data.score_data);
      setSubmissionData(null);
      setProcessing(false);
      setShowAnswer(true);
    });
  }

  const constructSubmissionData = () => {
    let bodyFormData = new FormData();
    
    bodyFormData.set('text', questionData[0].question);
    bodyFormData.set('text_answer', undefined);
    bodyFormData.set('answer', undefined);
    bodyFormData.set('q_ans', questionData[0].answer);
    bodyFormData.set('length', undefined);
    bodyFormData.set('file', submissionData.data);
    bodyFormData.set('duration', submissionData.duration);
    bodyFormData.set('type', id);
    bodyFormData.set('script', questionData[0].audio_script);
    bodyFormData.set('id', questionData[0].id);
    bodyFormData.set('lang', undefined);
    bodyFormData.set('practice', '1');
    bodyFormData.set('audio_text', submissionData.audio_text);

    if (parseInt(id) === 1) { // this is only read aloud.
      bodyFormData.set('strategy', submissionData.strategy);
    }

    return bodyFormData;
  }

  const submitButton = () => {
    return (
      <Button
        color="primary"
        className="bottom-btn"
        disabled={processing || submissionData === null}
        onClick={showResponse}
        size="sm"
      >{processing && <i className="fa fa-spinner">&nbsp;</i>}Submit</Button>
    );
  }

  const explainationVideoEnglish = () => {
    if (viewVideoEnglish) {
      return (
        <Button
          color="info"
          className="bottom-btn"
          // onClick={showVideoModalEnglish}
          disabled={false}
          size="sm"
        >Explanation (English)</Button>
      );
    }
    return "";
  }

  const explainationVideoHindi = () => {
    if (viewVideoHindi) {
      return (
        <Button
          color="info"
          className="bottom-btn"
          // onClick={showVideoModalHindi}
          disabled={false}
          size="sm"
        >Explanation (Hindi)</Button>
      );
    }
    return "";
  }

  const changeSelectPagination = (e) => {
    setQuestionLoaded(false);
    resetAnswerValues();

    const ques_number = e.target.value;
    setTimeout(() => {

      fetchQuestion(ques_number);
    }, 2000);
  }

  const prevButton = () => {
    setQuestionLoaded(false);
    resetAnswerValues();

    fetchQuestion(parseInt(currentQuestion) - 1);
  }

  const nextButton = () => {
    setQuestionLoaded(false);
    resetAnswerValues();

    fetchQuestion(parseInt(currentQuestion) + 1);
  }

  const questionSelectList = () => {
    return (
      <select 
        className="selectPage"
        onChange={changeSelectPagination}
        defaultValue={currentQuestion}
      >
        {Array.from({ length: totalQuestions }, (_, key) => <option key={key} value={key+1}>{key+1}</option>)}
      </select>
    );
  }

  const prevQuestionButton = () => {
    return (
      <Button
        color="info"
        className="bottom-btn"
        onClick={prevButton}
        disabled={(currentQuestion === 1)}
        size="sm"
      >Previous</Button>
    );
  }

  const nextQuestionButton = () => {
    return (
      <Button
        color="info"
        className="bottom-btn"
        onClick={nextButton}
        disabled={(totalQuestions === currentQuestion)}
        size="sm"
      >Next</Button>
    )
  }

  const questionAttemptedCount = () => {
    return (
      <Button
        color="info"
        className="bottom-btn"
        size="sm"
      >{questionData[0].attempted.length} x Attempted</Button>
    );
  }

  const wordOptionPopup = (status, word) => {
    setWordOption(status);
    setSelectedWord(word);
  }

  const switchedContent = () => {
    if (switched) {
      const script_array = questionData[0].audio_script.split(" ");
      return (
        <span className="unselectable">
          <h3>Question Audio</h3>
          <audio src={s3base + questionData[0].media_link} controls="controls" />

          <h3>Audio Script</h3>
          <div className="audio_script_text">
            {script_array.map((word, i) => {
              return <><span
                key={i}
                className="speach-text"
                style={{
                  cursor: "pointer",
                  paddingRight: "1px"
                }}
                onClick={() => wordOptionPopup(true, word)}
              >{word}</span>&nbsp;</>
            })}
          </div>
        </span>
      );
    }
  }

  const renderQuestionArea = (subcategory_id) => {
    const props = {
      questionData,
      resultMode,
      sendToPracticeFile
    };

    if (parseInt(subcategory_id) === 1) {
      props.questionStrategy = questionStrategy;
      props.updateQuestionStrategy = updateQuestionStrategy;
    }

    const questionComponents = {
      1: <ReadAloud {...props} key={currentQuestion} />,
      2: <RepeatSentence {...props} key={currentQuestion} />,
      3: <DescribeImage {...props} key={currentQuestion} />,
      4: <RetellLecture {...props} key={currentQuestion} />
    };

    return questionComponents[subcategory_id];
  }

  const sendToPracticeFile = (data) => {
    setSubmissionData(data);
  }

  return (
    <>
      <Helmet>
        <title>Top PTE Training Institute</title>
      </Helmet>

      {!questionLoaded &&
        <div className="content questionsArea">
          <div className="scoreLoader text-center showLoader">
            <span>Loading Question ...</span>
          </div>
        </div>
      }

      {questionLoaded &&
        <div className="content questionsArea">
          <Row>
            <Col xs={12}>
              <Card>
                {processing &&
                  <div className="scoreLoader text-center showLoader">
                    <span>AI Scoring Ongoing ...</span>
                  </div>
                }

                <PracticeHeader
                  questionData={questionData}
                  type={id}
                  aType={aType}
                  mark={mark}
                  prediction={0}
                  isAttempted={isAttempted}
                  hideNav={hideNav}
                  currentQuestion={currentQuestion}
                  totalQuestions={totalQuestions}
                  restartQuestion={restart}
                  updateQuestionData={updateQuestionData}
                />
                
                <CardBody>
                  {questionData.length === 0 ? (
                    <h5 className="text-center">No Question Found</h5>
                  ) : (
                    <>
                      {!restart &&
                        <Row className='unselectable'>
                          {renderQuestionArea(id)}
                          {showAnswer &&
                            <PracticeResult
                              resultData={resultData}
                              type={id}
                              historyData={historyData}
                            />
                          }
                        </Row>
                      }

                      {switchedContent()}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col xs={12}>
              {aiTokenBadge()}
              
              <div className="bottomBtns">
                <div className="row">
                  <Col xs={8}>
                    {submitButton()}
                    {restartButton()}
                    {explainationVideoEnglish()}
                    {explainationVideoHindi()}
                    {audioTranscriptSwitch()}
                  </Col>

                  {!hideNav ? (
                    <Col xs={4} className="text-right">
                      {questionSelectList()}
                      {prevQuestionButton()}
                      {nextQuestionButton()}
                    </Col>
                  ) : (
                    <Col xs={4}></Col>
                  )}
                </div>
              </div>
            </Col>

            <Col xs={12}>
              <div>
                <>
                  <div>
                    <>
                      <div className="bottomBtns">
                        {questionAttemptedCount()}
                        <QuestionTestedButton
                          questionData={questionData}
                        />
                        <QuestionReportButton
                          questionData={questionData}
                        />
                      </div>
                    </>
                    <Row className="discussion row">
                      <DiscussionArea
                        questionData={questionData}
                        isSubscribedCheck={store.getState().subscribed ? store.getState().subscribed : false}
                      />
                    </Row>
                  </div>
                </>
              </div>
            </Col>
          </Row>
        </div>
      }
    </>
  );
};

export default NewPractice;
