import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Row,
  Progress,
  Button
} from "reactstrap";
import Sound from 'react-sound';
import {base,s3base} from "api.js";
import NoMicrophone from "views/NoMicrophone.jsx";
import MicRecorder from 'mic-recorder-to-mp3';
import "components/custom.css";
let Mp3Recorder = new MicRecorder({ bitRate: 128 });
class IntroTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:25,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      correctFlag:true,
      soundStatus:false,
      file:''
    };
  }
  componentDidMount() {
    if (!this.props.answer) {
  	 var intervalId = setInterval(this.timer, 1000);
	   this.setState({intervalId: intervalId});
  	}
    navigator.getUserMedia({ audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        console.log('Permission Denied');
        this.setState({ isBlocked: true })
      },
    );
  }

  static getDerivedStateFromProps(props, state) {
  	/*if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			
  			return {recordCount:0,isRecording:false,secondCount:25,questionId: props.data.id,blobURL:'',reset:true};
  		}else{
  			if (!state.questionId) {
  				return {questionId: props.data.id};
  			}
  		}
  		
  		}
*/  	    
    
  }


  start = () => {
    this.setState({ soundStatus:true});
  };

  handleSongFinishedPlaying = () =>{
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder = new MicRecorder({ bitRate: 128 });
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',soundStatus:false});
        }).catch((e) => console.error(e));
    }
  }

  stop = () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob)
          let file = new File([blob], 'noname1'+this.state.questionId+'.mp3', { type: blob.type });
          this.setState({ blobURL, isRecording: false,recordCount:100,file:file });
          
          this.props.submit({'data':file },true)
          this.props.showResult();
        }).catch((e) => console.log(e));
    }
    
  };

  stopTest = () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob)
          //this.setState({ isRecording: false,recordCount:100 });
          let file = new File([blob], 'noname1.mp3', { type: blob.type });
          //this.props.submit({'data':file },true)
          //this.props.showResult();
      //this.props.nextQuestion({'data': file });
        }).catch((e) => console.log(e));
    }else{
      //this.props.nextQuestion({'data': this.state.file });
      //this.props.submit({'data': '' },true);
    }
  };

  updateCorrect = () =>{
     //this.props.submit({'data':''});
     this.setState({correctFlag:false})
  }
  skip = () => {
    clearInterval(this.state.intervalId);
    this.start();
    var recordId = setInterval(this.timerRecord, 1000);
    this.setState({recordId: recordId,secondCount:0});
  };
	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
			this.start();
			var recordId = setInterval(this.timerRecord, 1000);
			this.setState({recordId: recordId});
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}



	timerRecord = () => {
		if (this.state.recordCount === 30) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}
  
  render() {
  	let question ="";

    if (this.state.isBlocked) {
      return (<NoMicrophone />);
    }
    
    return (
      <>
      <Col xs={12}>
      	<h4 className="infoText">Read the prompt below. In 25 seconds, you must reply in your own word, as naturally as clear as possible. You will have 30 seconds to give your response</h4>
      </Col>
      <Col xs={12}>
        <Row>
          
        	<div className="col-lg-12 p-0 text-center">
      		 { !this.props.resultMode && <div className="audio-box">
              <h4>Recorded Answer</h4>
              <p><strong>Current status:</strong></p>
              <p> { this.state.blobURL === '' && this.state.isRecording?"Recording started": 'Recording starts in '+this.state.secondCount+ ' seconds' } </p>
              {false && this.state.blobURL === '' && !this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.skip} size="sm">Skip</Button></div> }
              <hr></hr>
              {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
              {false && this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.stop} size="sm">Stop</Button></div>}
              <hr></hr>
              <Progress color="info" value={(this.state.recordCount*2.5)} />
              <div className="audo-line"><span className="progress-bar-fill-Read intro"></span></div>
            </div> }
  		    </div>
        </Row>
        <Col xs={12}>
          { this.state.blobURL && false && <audio src={this.state.blobURL} controls="controls" />}
        </Col>
		    
      </Col> 
      <Col xs={12}><h5>Please introduce yourself. For example you could talk one or more of the following</h5>
      <ul>
        <li>Your interests</li>
        <li>Your plans for future study</li>
        <li>Why you want to study abroad</li>
        <li>Why you need to learn english</li>
        <li>Why you choose this test</li>
      </ul>
      </Col>
      {!this.props.resultMode && this.state.soundStatus && <Sound
                  url={s3base + "/ptedata/ptemedia/beep.mp3"}
                  playStatus={Sound.status.PLAYING}
                  onFinishedPlaying={this.handleSongFinishedPlaying}
                />}    
      </>
    );
  }
}
export default IntroTest;