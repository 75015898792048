import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Alert,
  Col,Button,
  CardFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import { setAlert} from 'action/index.js';
import API from "api.js";
import { NavLink } from "react-router-dom";
import { setUser} from 'action/index.js';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import {store,persistor} from 'store/index';
import { connect } from 'react-redux';
import { thead, tbody } from "variables/general";
const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};
class Mock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      password:'',
      new_password:'',
      confirm_password:'',
      error_bag:null
    };
    this.validator = new SimpleReactValidator();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

  componentDidMount() {

  }
  postSignUp = async(e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    this.setState({error_bag:null});
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.set('password', this.state.password);
    bodyFormData.set('new_password', this.state.new_password);
    bodyFormData.set('confirm_password', this.state.confirm_password);
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: '/changePassword',
        data: bodyFormData
      })
    }catch(error) {
        console.error(error);  
        this.setState({error:{error} });
    };
    
    if (res.status === 200) {
      store.dispatch(setAlert({'flag':true,'type':1,'message':"Password changed successfully!"}));       
    }else if (res.response.status === 422) {
      
      this.setState({error:res.response });
      let str = '';
      //Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
      this.setState({error_bag:"Current password not matched!"});
    }
    
    
  }

  toggle = tab => {
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab});
    }
  }
 
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                  <CardTitle tag="h4">Change Password</CardTitle>
                </CardHeader>
                <CardBody>
                   <Form>
                    
                    <Row>
                      <Col className="" md="7">
                        <FormGroup>
                          <label>Old Password</label>
                          <Input
                            defaultValue={this.state.password}
                            name="password"
                            placeholder="Old Password"
                            type="password"
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('old password', this.state.password, 'required')}
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup>
                          <label>New Password</label>
                          <Input
                            defaultValue={this.state.new_password}
                            name="new_password"
                            placeholder="New Password"
                            type="password"
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('new password', this.state.new_password, 'required')}
                        </FormGroup>
                      </Col>
                      <Col className="" md="7">
                        <FormGroup>
                          <label>Confirm Password</label>
                          <Input
                            defaultValue={this.state.confirm_password}
                            name="confirm_password"
                            placeholder="Confirm Password"
                            type="password"
                            onChange={(e) => { this.changeInput(e)} }
                          />
                          {this.validator.message('confirm password', this.state.confirm_password, `required|in:${this.state.new_password}`, {messages: {in: 'Passwords need to match!'}})}
                        </FormGroup>
                      </Col>
                    </Row>
                    
                    
                   
                    
                  </Form>
                   
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={this.postSignUp}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mock);
