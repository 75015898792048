import React, { useState, useEffect } from 'react';
import { useSpeechRecognition } from "react-speech-recognition";

export default function SpeechToText({
	updateTranscript
}) {
	const LISTENING = 1;
	const LISTENING_FINISH = 2;

	const {
		transcript,
		listening,
		resetTranscript,

		isMicrophoneAvailable,
		interimTranscript
	} = useSpeechRecognition();
	const [actOnEvent, setActOnEvent] = useState(false);


	useEffect(() => {
		if (listening) {
			setActOnEvent(true);
		} else if (actOnEvent && !listening) {
			updateTranscript(transcript);
			setActOnEvent(false);
		}
	}, [listening]);


	useEffect(() => {
		// do nothing. don't remove.
	}, [interimTranscript]);

	return "";
}