import axios from 'axios';
import cookie from 'react-cookies';
import { store, persistor } from 'store/index';
import { setToken, setLoader, setSub } from 'action/index.js';
import getBaseUrl from './base.js';

store.subscribe(listener);
const base = process.env.REACT_APP_API_URL;
// --------- End

// const base = getBaseUrl();
const s3base = 'https://s3.ap-southeast-2.amazonaws.com/lamedia21';
const axiosInstance = axios.create({
  baseURL: `${base}/api/v1`
});

axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

function select(state) {
  return state.token
}
function listener() {
  let token = select(store.getState())
  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer '+token;
}


const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    // Handle errors
  }
  store.dispatch(setLoader(true));
  console.error(error);
  if(error.response.status === 401){
  	store.dispatch(setToken(null));
  }
  if(error.response.status === 402){
    store.dispatch(setSub(false));
  }
  store.dispatch(setLoader(true));
  return error;
}
const isHandlerEnabled = (config={}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ?
    false : true
}

const requestHandler = (request) => {
  if (
    request.url !== "check/payment" && 
    request.url !== "delete/question/responseNew" && 
    request.url !== "check/answer1" && 
    request.url !== "show/history" && 
    request.url !== "show/comment" && 
    request.url !== "show/notes" && 
    request.url !== "submit/mock" && 
    request.url !== 'set/mockTime' && 
    request.url !== 'set/tag' && 
    request.url !== 'dashboard/data2' &&
    (/^question\/detail\/\d{1,9}$/.test(request.url))
  ) {
    store.dispatch(setLoader(false));
  }

  return request
}

axiosInstance.interceptors.request.use(
  request => requestHandler(request)
)

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }
  store.dispatch(setLoader(true));
  // console.log(store.getState().token);
  return response
}

export default axiosInstance;
export {base,s3base};
