import React, { useState, useEffect } from 'react';
import UserNotes from "views/UserNotes.jsx";
import ShowHistory from "views/ShowHistory.jsx";
import QuestionComment from "views/QuestionComment.jsx";

export default function DiscussionArea({
	questionData,
	isSubscribedCheck
}) {

	const [commentsTab, setCommentTab] = useState(false);
	const [historyTab, setHistoryTab] = useState(false);
	const [boardTab, setBoardTab] = useState(false);
	const [notesTab, setNotesTab] = useState(false);
	const [history, setHistory] = useState([]);

	useEffect(() => {
		if (commentsTab) {
			setHistoryTab(false);
			setBoardTab(false);
			setNotesTab(false);
		}
	}, [commentsTab]);

	useEffect(() => {
		if (historyTab) {
			setCommentTab(false);
			setBoardTab(false);
			setNotesTab(false);
		}
	}, [historyTab]);

	useEffect(() => {
		if (boardTab) {
			setCommentTab(false);
			setHistoryTab(false);
			setNotesTab(false);
		}
	}, [boardTab]);

	useEffect(() => {
		if (notesTab) {
			setCommentTab(false);
			setHistoryTab(false);
			setBoardTab(false);
		}
	}, [notesTab]);

	const showCommentsTab = (e) => {
		e.preventDefault();
		setCommentTab(true);
	}

	const showHistoryTab = (e) => {
		e.preventDefault();
		setHistoryTab(true);
	}

	const showBoardTab = (e) => {
		e.preventDefault();
		setBoardTab(true);
	}

	const showNotesTab = (e) => {
		e.preventDefault();
		setNotesTab(true);
	}

	const changeResponseArr = (data) => {
		setHistory(data);
	}

	return (
		<div className="discuTabs">
			<p className="mt-1 mb-2 text-data-warning">
				<i className="fa fa-exclamation-circle" aria-hidden="true"></i>{isSubscribedCheck === true ? ' Data available for the last 3 months.' : ' Data available for the last 1 month.'}
			</p>
			<ul className="nav nav-tabs">
				<li className={commentsTab ? "active" : ""}>
					<a
						href="#"
						onClick={showCommentsTab}
					><i className="fa fa-commenting-o" aria-hidden="true"></i> &nbsp;Discussion</a>
				</li>
				<li className={historyTab ? "active" : ""}>
					<a
						href="#"
						onClick={showHistoryTab}
					><i className="fa fa-list-ul" aria-hidden="true"></i> &nbsp;Me</a>
				</li>
				<li className={boardTab ? "active" : ""}>
					<a
						href="#"
						onClick={showBoardTab}
					><i className="fa fa-list-ul" aria-hidden="true"></i> &nbsp;Others</a>
				</li>
				<li className={notesTab ? "active" : ""}>
					<a
						href="#"
						onClick={showNotesTab}
					><i className="fa fa-pencil" aria-hidden="true"></i> &nbsp;Notes</a>
				</li>
			</ul>
			<div className="practiceContent">
				<div>
					{notesTab &&
						<UserNotes
							question={questionData[0].id}
						/>
					}
					{historyTab &&
						<ShowHistory
							all="0"
							changeResponseArr={changeResponseArr}
							question={questionData[0].id}
							flag={0}
						/>
					}
					{boardTab &&
						<ShowHistory
							all="1"
							changeResponseArr={changeResponseArr}
							question={questionData[0].id}
							flag={0}
						/>
					}
					{commentsTab &&
						<QuestionComment
							question={questionData[0].id}
						/>
					}
				</div>
			</div>
		</div>
	);
}