import React, { useState, useEffect } from 'react';
import {
	Button,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter
} from 'reactstrap';
import API from 'api.js';
import { store } from "store/index";
import { setAlert } from "action/index.js";

export default function QuestionTestedButton({
	questionData
}) {

	const [modal, setModal] = useState(false);
	const [reportText, setReportText] = useState("");

	const showModal = () => {
		setModal(!modal);
	}

	const changeReportText = (e) => {
		setReportText(e.target.value);
	}

	const submitReport = () => {
		if (reportText === ""){
			store.dispatch(setAlert({
				flag: true,
				type: 2,
				message: "Message cant't be empty"
			}));
			return ""
		}

		const bodyFormData = new FormData();
		bodyFormData.set('text', reportText);
		bodyFormData.set('question_id', questionData[0].id);

		API({
			method: 'POST',
			data: bodyFormData,
			url: 'report/question',
		}).then(data => {
			if (data.status === 200) {
				setReportText("");
				setModal(false);
				store.dispatch(setAlert({
					flag: true,
					type: 1,
					message: data.data.message
				}));
			}
		}).catch(() => {
			setModal(false);
		});
	}

	return (
		<>
			<Button
				color="info"
				style={{float: 'right'}}
				className="bottom-btn"
				onClick={showModal}
				disabled={false}
				size="sm"
			>Report</Button>

			<Modal isOpen={modal} size="lg">
				<ModalBody>
					<ModalHeader>Report the problem in this question</ModalHeader>
					<Input
						type="textarea"
						value={reportText}
						name="reportText"
						onChange={changeReportText}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						color="secondary"
						className="modalCloseBtn"
						onClick={submitReport}
					>Submit</Button>
					
					<Button
						color="secondary"
						className="modalCloseBtn"
						onClick={showModal}
					>Close</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}