import React,{Component, useState }  from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Dictaphone = (props) => {
  const { transcript, resetTranscript } = useSpeechRecognition()
  const [temp, setTemp] = useState(false);
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null
  }
  if (temp != props.status) {

  	if (props.status == true) {
      console.error("props.status: "+props.status)
  		resetTranscript();
  	}
  	setTemp(props.status);
  }
  props.setTrans(transcript);


  return "";
  
}


export default Dictaphone;