import React, { useState, useEffect } from 'react';

export default function Waiting({
	updateWaitingStatus
}) {

	const [seconds, setSeconds] = useState(3);
	const [intervalID, setIntervalID] = useState(null);

	useEffect(() => {
		let allow_decrease = true;
		const id = setInterval(() => {
			if (allow_decrease) setSeconds(seconds => (seconds - 1));
		}, 1000);
		setIntervalID(id);

		return (() => {
			allow_decrease = false;
		})
	}, []);

	useEffect(() => {
		if (seconds === 0) {
			clearInterval(intervalID);
			updateWaitingStatus(false);
		}
	}, [seconds]);

	const currentLabel = () => {
		if (seconds > 0) {
			return `Beginning in ${seconds} seconds...`;
		} else if (seconds === 0) {
			return "Loading audio...";
		} else {
			return "";
		}
	}

	return (
		<>
			{currentLabel()}
		</>
	);
}