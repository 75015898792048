import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWall from '../components/Frontend/FameWall';
import Testimonial from '../components/Frontend/Testimonial';
import VideoTuts from '../components/Frontend/VideoTuts';
import WhyLove from '../components/Frontend/WhyLove';
import { Link} from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class PteMockTest extends React.Component {
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
 render(){   
    return (
    <React.Fragment>
    <Header />
    <div class="innerPage">
    <section class="bannerOuter">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1>Maximize your exam score with the Best PTE tutorials and practice test</h1>
                        <span>Practice regularly with the Pearson PTE mock test online and be ready to ace the exam</span><br />
                        <a href="javascript:;" class="downArrow"><img src="images/banner-arrow.png" alt="banner-arrow" /></a>
                    </div>
                </div>
            </div>
        </section>
      </div>
      
      <section class="pteInfo">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-lg-offset-1">
              <p class="web-content">
                   PTE exam has become quite popular among the candidates who want to study abroad or permanently settle in a foreign (English speaking) country. When it comes to the PTE academic preparation, we find the aspirants always searching for the best study and practice material to crack the exam with flying colors. But, to nail the exam, what matters the most is how much practice one has done using the PTE academic mock test. More the number of practice tests you attempt, the more are the chances to get success in the final paper.
              </p>
              <p class="web-content">
                 The PTE mock exam sets play a crucial role when it comes to achieving your target score in the actual examination. These provide a real-time test environment to the candidates and help them deal with their fears associated with facing the actual exam. Apart from working as a confidence booster, such PTE exam mock test sets also help to understand the paper pattern thoroughly. 
              </p>
              <p class="web-content">
                 Do you also want to master your preparation by practicing the best Pearson PTE mock test online? Confused about who can provide you the top-notch coaching and PTE academic practice test material on which you can rely altogether?  Contact our experts over a call on <a href="tel:+61272525311" target="blank">+61272525311</a> or via mail at <b>info@20task.com</b> and get ready to excel in the exam with a perfect package of PTE tutorials, study material, and mock tests.
              </p>
          </div>
        </div>
      </div>
    </section>
    <section id="pte-modules">
         <div class="container">
            <div class="row text-center">
               <div class="col-lg-3 col-sm-3">
                  <Link to="/pte-listening">
                     <div class="module-wrapper">
                        <div class="module-icon">
                           <img src="images/listening.png" class="listening" />
                        </div>
                        <h3 class="module-title">Listening</h3>
                        <p class="module-description">Start preparing for the listening section using the latest practice material.</p>
                     </div>
                  </Link>
               </div>
               <div class="col-lg-3 col-sm-3">
                  <Link to="/pte-writing" >
                     <div class="module-wrapper">
                        <div class="module-icon">
                           <img src="images/writing.png" class="writing" />
                        </div>
                        <h3 class="module-title">Writing</h3>
                        <p class="module-description">Sharpen your writing skills with the best PTE writing practice material.</p>
                     </div>
                  </Link>
               </div>
               <div class="col-lg-3 col-sm-6">
                  <Link to="/pte-reading">
                     <div class="module-wrapper">
                        <div class="module-icon">
                           <img src="images/reading.png" class="reading" />
                        </div>
                        <h3 class="module-title">Reading</h3>
                        <p class="module-description">Get the intensive PTE reading practice material to skyrocket your score.</p>
                     </div>
                  </Link>
               </div>
               <div class="col-lg-3 col-sm-3">
                  <Link to="/pte-speaking">
                     <div class="module-wrapper">
                        <div class="module-icon">
                           <img src="images/speaking.png" class="speaking" />
                        </div>
                        <h3 class="module-title">Speaking</h3>
                        <p class="module-description">Get the guidance from our experts and remove all the hurdles that you face in PTE speaking test module.</p>
                     </div>
                  </Link>
               </div>
            </div>
         </div>
      </section>
    <WhyLove />
    <Testimonial />
    <Footer />
    </React.Fragment>);
    }
}

export default PteMockTest;