import React, { useState, useEffect } from 'react';
import {
	Button,
	Progress
} from 'reactstrap';

export default function RecordinBox({
	recordingWait,
	recordingStartsIn,
	listening,
	listening_finish,
	updateListeningState
}) {

	const WAITING = 0;
	const STARTED = 1;
	const FINISHED = 2;
	const [start, setStart] = useState(false);
	const [seconds, setSeconds] = useState(null);
	const [intervalId, setIntervalId] = useState(null);
	const [recordingStatus, setRecordingStatus] = useState(WAITING);
	const [recordSeconds, setRecordSeconds] = useState(0);
	const [recordTimerId, setRecordTimerId] = useState(null);


	useEffect(() => {
		if (recordingStartsIn > 0) {
			setSeconds(recordingStartsIn);
			setStart(true);
		}
	}, [recordingStartsIn]);


	useEffect(() => {
		if (start) {
			setStart(false);
			const id = setInterval(() => {
				setSeconds(seconds => (seconds - 1));
			}, 1000);
			setIntervalId(id);
		}
	}, [start]);


	useEffect(() => {
		if (seconds === 0) {
			clearInterval(intervalId);
			setRecordingStatus(STARTED);
			startRecording();
		}
	}, [seconds]);


	useEffect(() => {
		if (recordSeconds === 8) {
			stopRecording();
		}
	}, [recordSeconds]);


	const startRecording = () => {
		updateListeningState(listening);
		startRecordingTimer();
	}


	const startRecordingTimer = () => {
		const id = setInterval(() => {
			setRecordSeconds(recordSeconds => (recordSeconds + 1));
		}, 1000);
		setRecordTimerId(id);
	}


	const currentStatus = () => {
		if (seconds !== null) {
			if (recordingStatus === WAITING) {
				return `Recording starts in ${seconds} seconds`;
			} else if (recordingStatus === STARTED) {
				return "Recording started";
			}
		}
		return "";
	}


	const stopRecording = () => {
		setTimeout(() => {
			updateListeningState(listening_finish);
		}, 1000);
		clearInterval(recordTimerId);
		setRecordSeconds(8);
		setRecordingStatus(FINISHED);
	}

	return (
		<div className="audio-box">
			<h4>Recorded Answer</h4>
			<p>
				<strong>Current status:</strong>
			</p>
			<p>{currentStatus()}</p>
			<hr></hr>

			<div className="stop-btn">
				{recordingStatus === STARTED &&
					<Button color="success" onClick={stopRecording} size="sm">
						Stop
					</Button>
				}
			</div>
			<hr></hr>

			<Progress
				color="info"
				value={recordSeconds * 12.5}
			/>
		</div>
	);
}