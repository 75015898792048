import React, { useState, useEffect } from 'react';

export default function PracticeMainTimer({
	restartQuestion
}) {

	const [seconds, setSeconds] = useState(0);

	useEffect(() => {
		let allow_increase = true;
		setInterval(() => {
			if (allow_increase) {
				setSeconds(seconds => seconds + 1);
			}
		}, 1000);

		return(() => {
			allow_increase = false;
		});
	}, []);

	useEffect(() => {
		if (restartQuestion) {
			reset();
		}
	}, [restartQuestion]);

	const reset = () => {
		setSeconds(0);
	}

	return (
		<div className="timeRemaining">
			<p>
				<b>
					<i className="fa fa-clock-o"></i>
					<span>Time spent: {Math.floor(seconds / 60) +':'+ (seconds % 60)}</span>
				</b>
			</p>
		</div>
	);
}