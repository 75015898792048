import React, { useState, useEffect } from 'react';
import {
	Button
} from 'reactstrap';
import Sound from 'react-sound';
import { s3base } from 'api.js';

export default function Waiting({
	imageLoaded,
	updateWaitingStatus
}) {

	const [seconds, setSeconds] = useState(25);
	const [intervalId, setIntervalId] = useState(null);
	const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
	const [beep, setBeep] = useState(false);
	const beepurl = `${s3base}/ptedata/ptemedia/beep.mp3`;

	useEffect(() => {
		if (imageLoaded) {
			startWaiting();
		}
	}, [imageLoaded]);

	useEffect(() => {
		if (seconds === 0) {
			stopWaiting();
		}
	}, [seconds]);

	const updateBeepStatus = (status) => {
		setBeep(status);
		if (status) {
			changeBeepPlayStatus();
		}
	}

	const startWaiting = () => {
		const id = setInterval(() => {
			setSeconds(seconds => (seconds - 1));
		}, 1000);
		setIntervalId(id);
	}

	const stopWaiting = () => {
		clearInterval(intervalId);
		updateBeepStatus(true);
	}

	const skip = () => {
		stopWaiting();
	}

	const changeBeepPlayStatus = async () => {
		setPlayStatus(Sound.status.PLAYING);
	}

	const beepFinished = () => {
		updateWaitingStatus();
	}

	return (
		<>
			<p>
				Recording starts in {seconds} seconds
			</p>
			<div className='stop-btn'>
				<Button
					color="success"
					onClick={skip}
					size="sm"
				>Skip</Button>
			</div>
			<Sound
				url={beepurl}
				playStatus={playStatus}
				onFinishedPlaying={beepFinished}
				autoLoad={true}
			/>
		</>
	);
}