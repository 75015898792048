import React, { useState, useEffect } from 'react';
import {
	Button,
	Progress
} from 'reactstrap';

export default function Recording({
	recording,
	updateRecordingStatus
}) {

	const [progress, setProgress] = useState(0);
	const [progressId, setProgressId] = useState(null);

	useEffect(() => {
		if (recording) {
			startProgress();
		}
	}, [recording]);

	useEffect(() => {
		if (progress === 40) {
			stop();
		}
	}, [progress]);

	const startProgress = () => {
		const id = setInterval(() => {
			setProgress(progress => (progress + 1));
		}, 1000);
		setProgressId(id);
	}

	const stop = () => {
		updateRecordingStatus(false);
		clearInterval(progressId);
		setProgress(41);
	}

	return (
		<>
			{recording &&
				<>
					<p>Recording started</p>
					<div className='stop-btn'>
						<Button
							color="success"
							onClick={stop}
							size="sm"
						>Stop</Button>
					</div>
				</>
			}
			<hr></hr>

			<Progress
				color="info"
				value={(progress * 2.5)}
			/>
		</>
	);
}