import React from 'react';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import API,{base} from "../api.js";
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Refund extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false,
            list:[]
        }
    }

    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
        API({
              method: 'GET',
              url: "display/video",
              data:'json',
            }).then(data => {
                console.log(data.data.response.data)
              this.setState({list:data.data.response.data});
            }).catch(()=>{
                this.setState({lockSubmit:false});
            });
    }
 render(){   
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>20 Task</title>
     </Helmet>
    <Header />
        <section className="tutorialVd mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className="title">Tutorial <span>Videos</span></h2>
                    </div>
                    <div className="col-sm-12">
                        
                        <div className="videosCntnt">
                            {this.state.list.map((item)=>{
                                    return (<div className="videoBlk">
                                <div className="videoOuter">
                                    <iframe src={item.video} />
                                </div>
                                <p>{item.title}</p>
                            </div>);
                                })}
                            
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    <Footer />
    </React.Fragment>);
    }
}

export default Refund;