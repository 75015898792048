import React, { useState, useEffect } from 'react';
import {
	Input,
	Progress,
	Button
} from 'reactstrap';
import Sound from 'react-sound';
import { s3base } from 'api.js';

export default function QuestionAudio({
	updateQuestionAudioStatus,
	questionAudio,
	playQuestionAudio,
	updateWaitingStatus,
	updateRecordingStartsIn
}) {

	const [volume, setVolume] = useState(100);
	const [progress, setProgress] = useState(0);
	const [showSkip, setShowSkip] = useState(true);

	useEffect(() => {
		if (playQuestionAudio) setShowSkip(false);
	}, [playQuestionAudio]);

	const changeRange = (e) => {
		let { value } = e.target;
		value = ((value > 100) ? 100 : (value < 0 ? 0 : value));
		setVolume(parseInt(value));
	}

	const audioLoaded = (obj) => {
		const d = obj.duration / 1000 + 1;
		if (obj.duration > 0) {
			updateRecordingStartsIn(Math.ceil(d));
		}
	}

	const onPlaying = (obj) => {
		setProgress((obj.position * 100) / obj.duration);
	}

	const playbackFinished = () => {
		updateQuestionAudioStatus(false);
	}

	const skipWait = () => {
		updateWaitingStatus(false);
	}

	return (
		<>
			{playQuestionAudio &&
				<Sound
					url={`${s3base}${questionAudio}`}
					playStatus={Sound.status.PLAYING}
					onLoad={audioLoaded}
					onPlaying={onPlaying}
					onFinishedPlaying={playbackFinished}
					volume={volume}
				/>
			}
			Volume
			<Input
				placeholder="Volume"
				type="range"
				min="0"
				defaultValue={volume}
				step="1"
				onInput={changeRange}
				max="100"
			/>
			<br></br>
			<Progress
				color="info"
				value={progress}
			/>
			<div className="stop-btn">
				{showSkip &&
					<Button color="success" onClick={skipWait} size="sm">
						Skip
					</Button>
				}
			</div>
		</>
	);
}