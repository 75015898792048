import React, { useState, useEffect } from 'react';
import {
	Col,
	Row
} from 'reactstrap';
import { s3base } from 'api.js';
import SpeechRecognition from "react-speech-recognition";
import MicRecorder from "mic-recorder-to-mp3";
import getBlobDuration from "get-blob-duration";
import RecordingBox from 'components/NewPractice/Speaking/DescribeImage/RecordingBox';
import SpeechToText from 'components/NewPractice/Speaking/SpeechRecognition/SpeechRecognition';
import StayConnected from 'components/NewPractice/Speaking/StayConnected';

let Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default function DescribeImage({
	questionData,
	resultMode,
	sendToPracticeFile
}) {

	const NONE = "none";
	const BLOCK = "block";
	const [imageLoaded, setImageLoaded] = useState(NONE);
	const [recordedAudioURL, setRecordedAudioURL] = useState("");
	const [transcript, setTranscript] = useState("");
	const [blobFile, setBlob] = useState(null);
	const [fileBuffer, setBuffer] = useState(null);


	const updateTranscript = (trans) => {
		setTranscript(trans);
	}

	const startCapture = () => {
		SpeechRecognition.startListening({ continuous: true, language: "en-IN" });

		Mp3Recorder = new MicRecorder({ bitRate: 128 });
		Mp3Recorder.start();
	}

	const stopCapture = () => {
		SpeechRecognition.stopListening();

		Mp3Recorder.stop()
		.getMp3()
		.then(async ([buffer, blob]) => {
			setBlob(blob);
			setBuffer(buffer);
		})
		.catch(err => {
			console.log('Error Stopping.', err);
		});
	}

	const getBlobURL = (blob, file) => {
		let url = null;
		try {
			url = URL.createObjectURL(file);
		} catch (err) {
			url = URL.createObjectURL(blob);
		}
		return url;
	};

	const getFile = (blob, buffer) => {
		let file = null;
		try {
			file = new File([blob], "noname.mp3", { type: blob.type });
		} catch (err) {
			file = new File(buffer, "noname.mp3", {
				type: blob.type,
				lastModified: Date.now(),
			});
		}
		return file;
	};

	const getDuration = async (blob) => {
		let duration = 0;
		duration = await getBlobDuration(blob)
		.then((d) => {
			return d;
		})
		.catch(async (e) => {
			return await getBlobDuration(URL.createObjectURL(blob));
		});
		return duration;
	};

	const handleImageLoaded = () => {
		setImageLoaded(BLOCK);
	}


	useEffect(() => {
		if (blobFile !== null && fileBuffer !== null) {
			const submitPractice = async () => {
				const duration = await getDuration(blobFile);
				const file = getFile(blobFile, fileBuffer);
				const blobURL = getBlobURL(blobFile, file);
				setRecordedAudioURL(blobURL);

				const dataToSubmit = {
					data: file,
					audio_text: transcript,
					duration: duration
				};
				sendToPracticeFile(dataToSubmit);
			}
			submitPractice();
		}
	}, [blobFile, fileBuffer]);

	return (
		<>
			<StayConnected />
			<SpeechToText
				updateTranscript={updateTranscript}
			/>
			<Col xs={12}>
				<h4 className="infoText"></h4>
			</Col>
			<Col xs={12}>
				<Row>
					<Col lg={6}>
						<img
							alt="Loading..."
							width="500"
							onLoad={handleImageLoaded}
							src={`${s3base}${questionData[0].media_link}`}
							style={{
								display: imageLoaded
							}}
						/>
						{imageLoaded === NONE &&
							<div style={{margin:'145px'}}>Loading...</div>
						}
					</Col>

					<RecordingBox
						questionData={questionData}
						resultMode={resultMode}
						imageLoaded={imageLoaded === BLOCK ? true : false}
						startCapture={startCapture}
						stopCapture={stopCapture}
					/>
				</Row>
			</Col>

			<Col xs={12}>
				{recordedAudioURL !== "" &&
					<audio src={recordedAudioURL} controls="controls" />
				}
			</Col>
		</>
	);
}