import React,{Component} from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, browserHistory } from "react-router-dom";
import Sub from "layouts/Sub.jsx";
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import cookie from 'react-cookies';
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Provider } from 'react-redux'
import { createStore } from 'redux';
import reducer from '../src/reducer/index';
import { connect } from 'react-redux';
import Loader from 'react-loader';
import { setAlert, intStatus } from 'action/index.js';
import { setLoader } from "action";
import NotificationAlert from "react-notification-alert";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import { store, persistor } from 'store/index';
import Home from './page/Home';
import Home1 from './page/pteportal/Home';
import ContactPortal from './page/pteportal/Contact';
import AboutPortal from './page/pteportal/About';
import SpeakingPortal from './page/pteportal/Speaking';
import WritingPortal from './page/pteportal/Writing';
import ReadingPortal from './page/pteportal/Reading';
import ListeningPortal from './page/pteportal/Listening';
import ResetPassword from './page/pteportal/auth/ResetPassword';
import LoginPortal from './page/pteportal/auth/Login';
import SignupPortal from './page/pteportal/auth/Register';
import ForgotPortal from './page/pteportal/auth/Forgot';
import ThankYouPortal from './page/pteportal/ThankYou';
import VideoCourse from './page/VideoCourse';
import MockPlan from './page/MockPlan';
import Contact from './page/Contact';
import About from './page/About';
import Naati from './page/Naati';
import Pte from './page/Pte';
import ReadingPte from './page/ReadingPte';
import WritingPte from './page/WritingPte';
import SpeakingPte from './page/SpeakingPte';
import ListeningPte from './page/ListeningPte';
import PteOnlineClasses from './page/PteOnlineClasses';
import PteMockTest from './page/PteMockTest';
import PteStudyMaterial from './page/PteStudyMaterial';
import Term from './page/Term';
import Feedback from './page/Feedback';
import DailyFeedback from './page/DailyFeedback';
import Refund from './page/Refund';
import VideoAll from './page/VideoAll';
import TestimonialAll from './page/TestimonialAll';
import ScoreCardAll from './page/ScoreCardAll';
import PteOnlineCoaching from './page/PteOnlineCoaching';
import NaatiOnlineCoaching from './page/NaatiOnlineCoaching';
import PteCoachingSydney from './page/PteCoachingSydney';
import IeltsOnlineCoaching from './page/IeltsOnlineCoaching';
import IeltsCoachingParramatta from './page/IeltsCoachingParramatta';
import IeltsCoachingSydney from './page/IeltsCoachingSydney';
import NaatiCoachingSydney from './page/NaatiCoachingSydney';
import Sitemap from './page/Sitemap';
import NotFound from './page/NotFound';
import ThankYou from './page/ThankYou';
import ThankYouRegister from './page/ThankYouRegister';
import robot from './components/robots.txt';
import Sitemap1 from './components/sitemap.xml';
import fb from './components/googleaa7a8b2d6a176fd2.txt';
const hist = createBrowserHistory();

const mapStateToProps = (state) => {
   return {
      token: state.token,
      loaded: state.loaded,
      user: state.user,
      subscribed:state.subscribed,
      alert:state.alert
   };
};

class ReactRouteAll extends Component {


	isPortalUrl = (pathname) => {
		const starts_with = pathname.indexOf('/admin/');
		if (starts_with === 0) {
			document.querySelector("html").classList.add('student-portal');
		} else {
			document.querySelector("html").classList.remove('student-portal');
		}
	}

	isDashboard = (pathname) => {
		const starts_with = pathname.indexOf('/admin/dashboard');
		if (starts_with === 0) {
			document.querySelector("html").classList.add('student-dashboard');
		} else {
			document.querySelector("html").classList.remove('student-dashboard');
		}
	}


	componentDidMount() {
		store.dispatch(setLoader(true));
		store.dispatch(intStatus(true));
		if (this.props.loaded) {
			window.addEventListener("load", () => {
				let snackbarId;

				// now we listen for network status changes
				window.addEventListener("online", () => {
					closeSnackbar(snackbarId);
					store.dispatch(intStatus(true));
					document.getElementById("internet-down").remove();
				});


				window.addEventListener("offline", () => {

					let dr = store.dispatch(intStatus(false));

					snackbarId = enqueueSnackbar("No internet connection", {
						variant: "error",
						persist: true,
					});

					const div = document.createElement("div");
					div.className = "modal-backdrop fade show";
					div.id = "internet-down";
					if(document.getElementsByClassName('wrapper').length > 0) {
						document.getElementsByClassName('wrapper')[0].appendChild(div);
					} else if(document.getElementsByClassName('dashboardPage').length > 0) {
						document.getElementsByClassName('dashboardPage')[0].appendChild(div);
					} else {
						document.getElementById('root').appendChild(div);
					}
				});
			});
		}

		// check pathname on page load.
		setTimeout(() => {
			this.isPortalUrl(hist.location.pathname);
			this.isDashboard(hist.location.pathname);
		}, 500);

		// listen to navigation (path change.)
		this.unlisten = hist.listen( location =>  {
			this.isPortalUrl(location.pathname);
			this.isDashboard(location.pathname);
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}


	notify = (type,message) => {
    
		switch (type) {
			case 1:
			  type = "success";
			  break;
			case 2:
			  type = "danger";
			  break;
			default:
			  break;
		}
		var options = {};
		options = {
			place: 'tr',
			message: (
			  <div>
			    <div>
			      {message}
			    </div>
			  </div>
			),
			type: type,
			icon: "now-ui-icons ui-1_bell-53",
			autoDismiss: 7
		};
	   this.refs.notificationAlert.notificationAlert(options);
	};


	render(){
		const portal = [
						{ path:"/", component:Home1 },
						{ path:"/contact", component:ContactPortal },
						{ path:"/thank-you", component:ThankYouPortal },
						{ path:"/about", component:AboutPortal },
						{ path:"/speaking", component:SpeakingPortal },
						{ path:"/writing", component:WritingPortal },
						{ path:"/reading", component:ReadingPortal },
						{ path:"/listening", component:ListeningPortal }
						];
		const portalAuth = [
						{ path:"/auth/login", component:LoginPortal },
						/* { path:"/auth/register", component:SignupPortal }, */
						{ path:"/auth/forgot", component:ForgotPortal },
						{ path:"/auth/resetpassword", component:ResetPassword }
						];
		const subAuth = [
						{ path:"/auth/login", component:LoginPortal }
						];	
		const front = [
						{ path:"/", component:Home },
			      		{ path:"/robots.txt", component:robot },
			      		{ path:"/googleaa7a8b2d6a176fd2.html", componen:fb },
			      		{ path:"/video-courses", component:VideoCourse },
			     		{ path:"/about", component:About },
			      		{ path:"/mock-feedback/:id", component:Feedback }, 
			      		{ path:"/daily-feedback", component:DailyFeedback }, 
			      		{ path:"/contact", component:Contact }, 
			      		{ path:"/pte-coaching-classes-parramatta", component:Pte }, 
			      		{ path:"/naati-coaching-classes-parramatta", component:Naati }, 
			      		{ path:"/pte-reading-test-practice-material", component:ReadingPte }, 
			      		{ path:"/pte-speaking-test-practice-material", component:SpeakingPte }, 
			      		{ path:"/pte-writing-test-practice-material", component:WritingPte }, 
			      		{ path:"/pte-listening-test-practice-material", component:ListeningPte}, 
			      		{ path:"/pte-online-classes", component:PteOnlineClasses },
			     	 	{ path:"/pte-mock-test", component:PteMockTest },
			      		{ path:"/pte-study-material", component:PteStudyMaterial },
			      		{ path:"/term", component:Term },
			      		{ path:"/mock-plan", component:MockPlan },
			     	 	{ path:"/video-all", component:VideoAll },
			      		{ path:"/testimonial-all", component:TestimonialAll },
			      		{ path:"/refund-policies", component:Refund },
			      		{ path:"/scorecard", component:ScoreCardAll },
			      		{ path:"/sitemap", component:Sitemap },
			      		{ path:"/sitemap.xml", component:Sitemap1 },
			      		{ path:"/pte-online-coaching", component:PteOnlineCoaching },
			      		{ path:"/naati-ccl-online-coaching", component:NaatiOnlineCoaching },
			      		{ path:"/pte-coaching-sydney", component:PteCoachingSydney },
			      		{ path:"/ielts-online-coaching", component:IeltsOnlineCoaching },
			     	 	{ path:"/ielts-coaching-parramatta", component:IeltsCoachingParramatta },
			      		{ path:"/ielts-coaching-sydney", component:IeltsCoachingSydney },
			      		{ path:"/naati-ccl-coaching-sydney", component:NaatiCoachingSydney },
			      		{ path:"/thank-you", component:ThankYou },
			      		{ path:"/register-success", component:ThankYouRegister }
		];
		const isMainSite = true;
		if (this.props.alert.flag) {
			this.notify(this.props.alert.type,this.props.alert.message);
			store.dispatch(setAlert({'flag':false}));
		}
		return(
			<React.Fragment>
			<Loader loaded={this.props.loaded}  />
			<SnackbarProvider maxSnack={2} />
			<NotificationAlert ref="notificationAlert" />
			{!store.getState().is_portal && store.getState().is_main && <Router history={hist}>
						    <Switch>
						      
						        {front.length >0 && front.map(props => <Route {...props} exact />)}
							 { <Route path="/auth" render={props => <AuthLayout {...props} />} />}
						      { (this.props.token != null)  && <Route path="/admin" render={props => <AdminLayout {...props} />} />}
						      { (this.props.token != null) && <Route path="/sub/subscription" render={props => <Sub {...props} />} />}
						      { (this.props.token != null) && <Route path="/sub/payment/form" render={props => <Sub {...props} />} />}
						      { (this.props.token != null) && <Route path="/sub/success" render={props => <Sub {...props} />} />}
						      {  false && (this.props.token != null) && <Redirect to="/admin/dashboard" />}
						      { (this.props.token === null) && <Redirect to="/auth/login" />}
						      {  (this.props.token != null) && <Redirect to="/admin/dashboard" />}
						      { false && (this.props.token != null) && !this.props.subscribed && <Redirect to="/sub/subscription" />}
			
						    	<Route component={NotFound} exact />	
						    </Switch>
						  </Router>}
				{store.getState().is_portal && store.getState().is_main && <Router history={hist}>
						    <Switch>
						      
						        {portal.length >0 && portal.map((props, index) => <Route key={index} {...props} exact />)}
						        {portalAuth.length >0 && portalAuth.map((props, index) => <Route key={index} {...props} exact />)}
							 
						      { (this.props.token != null)  && <Route path="/admin" render={props => <AdminLayout {...props} />} />}
						      { (this.props.token != null) && <Route path="/sub/subscription" render={props => <Sub {...props} />} />}
						      { (this.props.token != null) && <Route path="/sub/payment/form" render={props => <Sub {...props} />} />}
						      { (this.props.token != null) && <Route path="/sub/success" render={props => <Sub {...props} />} />}
						      {  false && (this.props.token != null) && <Redirect to="/admin/dashboard" />}
						      { (this.props.token === null) && <Redirect to="/auth/login?session-expired" />}
						      {  (this.props.token != null) && <Redirect to="/admin/dashboard" />}
						      { false && (this.props.token != null) && !this.props.subscribed && <Redirect to="/sub/subscription" />}
			
						    	<Route component={NotFound} exact />	
						    </Switch>
						  </Router>}
				{!store.getState().is_main && <Router history={hist}>
						    <Switch>
						      
						        
						       {subAuth.length > 0 && subAuth.map(props => <Route {...props} exact />)}
							 
						      { (this.props.token != null)  && <Route path="/admin" render={props => <AdminLayout {...props} />} />}
						      { (this.props.token != null) && <Route path="/sub/subscription" render={props => <Sub {...props} />} />}
						      { (this.props.token != null) && <Route path="/sub/payment/form" render={props => <Sub {...props} />} />}
						      { (this.props.token != null) && <Route path="/sub/success" render={props => <Sub {...props} />} />}
						      {  false && (this.props.token != null) && <Redirect to="/admin/dashboard" />}
						      { (this.props.token === null) && <Redirect to="/auth/login" />}
						      {  (this.props.token != null) && <Redirect to="/admin/dashboard" />}
						      { false && (this.props.token != null) && !this.props.subscribed && <Redirect to="/sub/subscription" />}
			
						    	<Route component={NotFound} exact />	
						    </Switch>
						  </Router>}
			  </React.Fragment>
			);
	}
}

export default connect(mapStateToProps,null)(ReactRouteAll);