import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

const TopNavPills = ({ toggle, activeTab }) => {
  return (
    <Nav
      className="nav-pills-primary nav-pills-icons justify-content-center"
      pills
      role="tablist"
      tabs
    >
      <NavItem>
        <NavLink
          className={activeTab === 1 ? "active" : ""}
          onClick={() => {
            toggle(1);
          }}
        >
          Speaking
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === 2 ? "active" : ""}
          onClick={() => {
            toggle(2);
          }}
        >
          Writing
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === 3 ? "active" : ""}
          onClick={() => {
            toggle(3);
          }}
        >
          Reading
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={activeTab === 4 ? "active" : ""}
          onClick={() => {
            toggle(4);
          }}
        >
          Listening
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default TopNavPills;
