import React, { useState } from 'react';
import Waiting from 'components/NewPractice/Speaking/DescribeImage/Waiting';
import Recording from 'components/NewPractice/Speaking/DescribeImage/Recording';

export default function RecordingBox({
	questionData,
	resultMode,
	imageLoaded,
	startCapture,
	stopCapture
}) {

	const [waiting, setWaiting] = useState(true);
	const [recording, setRecording] = useState(false);
	const [finished, setFinished] = useState(false);

	const updateWaitingStatus = (status) => {
		setWaiting(status);
		if (!status) {
			updateRecordingStatus(true);
		}
	}

	const updateRecordingStatus = (status) => {
		setRecording(status);
		if (status) {
			startCapture();
		} else {
			stopCapture();
			updateFinishedStatus(true);
		}
	}

	const updateFinishedStatus = (status) => {
		setFinished(status);
	}

	return (
		<div className="col-lg-6 p-0 text-center">
			{!resultMode &&
				<div className="audio-box">
					<h4>Recorded Answer</h4>
					<p><strong>Current status:</strong></p>
					{finished && 
						<p>Recording Finished</p>
					}
					{waiting &&
						<Waiting
							imageLoaded={imageLoaded}
							updateWaitingStatus={updateWaitingStatus}
						/>
					}

					<Recording
						recording={recording}
						updateRecordingStatus={updateRecordingStatus}
					/>
				</div>
			}
		</div>
	);
}