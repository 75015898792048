import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";
import cookie from 'react-cookies'
import axios from 'axios';
import "components/custom.css";
import API from "api.js";
import NotificationAlert from "react-notification-alert";
import { Redirect, NavLink } from "react-router-dom";
class Subscription extends React.Component {
  state =  {
    cvc: '',
    message: '',
    expYear: '',
    expMonth: '',
    cardNumber: '',
    formProcess: false,
    paymentStatus:false,
    plan:false
  };
 
  componentDidMount() {
    this.loadPlan();
    this.loadStripe();    
  }

  loadPlan = () =>{
      API({
        method: 'GET',
        url: 'plan/'+this.props.match.params.id,
      }).then(data => {
        console.log(data.data.data)
        this.setState({plan:data.data.data},function(){
          
        })
    }).catch(()=>{
        
    });
  }
 
  handleChange = (evt) => {
    this.setState({
      [evt.target.name] : evt.target.value
    });
  }
  
  notify = (type,message) => {
    
    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  pay = async (e) => {
 
    e.preventDefault();
 
    this.setState({
      formProcess: true
    });
 
    window.Stripe.card.createToken({
      number: this.state.cardNumber,
      exp_month: this.state.expMonth,
      exp_year: this.state.expYear,
      cvc: this.state.cvc
    },async (status, response) => {  
      if (status === 200) {
        this.setState({
          message: ``,
          formProcess: false
        });
        var bodyFormData = new FormData();
        bodyFormData.set('plan', this.props.match.params.id);
        bodyFormData.set('token', response.id);
        let res = null;
        try{
          res = await API({
            method: 'POST',
            url: 'subscribe',
            data: bodyFormData,
          })
        }catch(error) {
            console.log(error);
        };
        if(res){
          if (res.status === 200) {
            this.notify(1,res.data.message);
            this.setState({paymentStatus:true});        
          }
        }
 
      } else {
        this.setState({
          message: response.error.message,
          formProcess: false
        });
      }
    });
  }
 
 
  loadStripe = () => {
     
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey('pk_live_JIwxa9FoBUiNl8YZcQClauSQ00aVGdi4OQ');
        // window['Stripe'].setPublishableKey('pk_test_f3HFKuah7T4BNhb9cReEXjEY00d143JEoD');
      }
      window.document.body.appendChild(s);
    }
  }
  render() {

    if (this.state.paymentStatus) {
      this.props.history.push(`/sub/success?p=${this.state.plan.price}`);
    }
    return (
    <div className="container" style={{height:'100vh'}}>
    <NotificationAlert ref="notificationAlert" />    
        <div className="row">
            <div className="col-xs-12 col-md-6 mr-auto  pt-5 subPaymentPage">
              <div className="panel panel-default">
                <div className="panel-body mt-5" style={{borderRight:"1px black ridge"}}>
                  <h3 style={{color:"#94c23c"}}>Plan Detail(s)</h3>
                  <b>Price - $ {this.state.plan && this.state.plan.price}</b><br/>
                 <b> Duration - {this.state.plan && this.state.plan.duration} Month(s)</b><br/> 
                 {this.state.plan && this.state.plan.is_video !== 0 && <b>Video Course Duration - {this.state.plan && this.state.plan.video_duration} Month(s)</b>}
                  <div className="plan-detail">{this.state.plan && (this.state.plan.is_video)?<i class="fa fa-check grn-color" aria-hidden="true"></i>:<i class="fa fa-times text-danger" aria-hidden="true"></i>} <span> Video Course Access</span></div>
                  <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span>{this.state.plan && this.state.plan.mock_count} AI Scored Full Mock Test</span></div>
                  <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span> {this.state.plan && this.state.plan.mock_count*4} AI Scored Sectional Mock Test</span></div>
                  <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span>  Feedback for every question</span></div>
                  <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span>  In-Depth Analysis for every question you attempt</span></div>
                  <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span>   Improvement suggestions</span></div>
                  <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span>   Section-wise analysis</span></div>
                  <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span>  Section-wise analysis </span></div>
                  <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span>  Progress Tracker to help you monitor your performance</span></div>
                  <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span> Access to weekly prediction files </span></div>
                  <div className="plan-detail"><i class="fa fa-check grn-color" aria-hidden="true"></i> <span>  Access to proven templates</span></div>
                </div>
              </div>
              
            </div>
            <div className="col-xs-12 col-md-6 mr-auto  pt-5 subPaymentPage">            
                <div className="panel panel-default">
                    <form onSubmit={this.pay}>
                      <div className="panel-body mt-5">
                          
                          <p><b>{this.state.message}</b></p>
                          <div className="row">

                              <div className="col-xs-12 col-md-12">
                                  <div className="form-group">
                                      <label>CARD NUMBER</label>
                                      <div className="input-group">
                                          <input type="text" className="form-control" placeholder="Valid Card Number" name="cardNumber" maxLength="18" onChange={this.handleChange} />
                                          <span className="input-group-addon"><span className="fa fa-credit-card"></span></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-xs-7 col-md-7">
                                  <div className="form-group">
                                      <label><span className="hidden-xs">EXPIRATION</span> Date</label>
                                      <div className="row">
                                          <div className="col-md-6">
                                              <div className="form-group">
                                                  <select name="expMonth" className="form-control"  onChange={this.handleChange}>
                                                      <option value="">Select Month</option>
                                                      <option value="1">01</option>
                                                      <option value="2">02</option>
                                                      <option value="3">03</option>
                                                      <option value="4">04</option>
                                                      <option value="5">05</option>
                                                      <option value="6">06</option>
                                                      <option value="7">07</option>
                                                      <option value="8">08</option>
                                                      <option value="9">09</option>
                                                      <option value="10">10</option>
                                                      <option value="11">11</option>
                                                      <option value="12">12</option>
                                                  </select>
                                              </div>
                                          </div>
                                          <div className="col-md-6 pull-right">
                                              <div className="form-group">
                                                  <select name="expYear" className="form-control" onChange={this.handleChange}>
                                                      <option value="">Select Year</option>
                                                      <option value={new Date().getYear() - 100}>{1900 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 99}>{1901 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 98}>{1902 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 97}>{1903 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 96}>{1904 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 95}>{1905 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 94}>{1906 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 93}>{1907 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 92}>{1908 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 91}>{1909 + new Date().getYear()}</option>
                                                      <option value={new Date().getYear() - 90}>{1910 + new Date().getYear()}</option>                                                
                                                  </select>
                                              </div>
                                          </div>
                                      </div>
                                  </div>                            
                              </div>
                              <div className="col-xs-5 col-md-5 pull-right">
                                  <div className="form-group">
                                      <label>CVV CODE</label>
                                      <input type="text" name="cvv" className="form-control" placeholder="CVC" maxLength="4" onChange={this.handleChange} />
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="panel-footer">
                          <div className="row">
                              <div className="col-xs-12 col-md-12">
                                  { (this.state.formProcess)? (
                                      <span className="btn btn-warning btn-lg btn-block">Please wait...</span>
                                    ) : (
                                      <button className="btn btn-warning btn-lg btn-block">Process payment</button>
                                    )}
                              </div>
                          </div>
                      </div>
                  </form>
                  <p className="mt-2"></p>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Subscription;
