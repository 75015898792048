import React, { Component } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  NavLink as NavLink1,
  Nav,
  NavItem,
} from "reactstrap";
import API from "api.js";
import { NavLink, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { thead, tbody } from "variables/general";
import TopNavPills from "components/SectionalTest/TopNavPills";

class MockResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeTab: 1,
      currentSection: "Speaking Section",
    };
  }
  componentDidMount() {
    let new_format = 0;
    if (this.props.location.pathname === "/admin/results") {
      new_format = 1;
    }
    this.getMocks(new_format);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.pathname === "/admin/results") {
        this.getMocks(1);
      } else if (this.props.location.pathname === "/admin/mock-result") {
        this.getMocks(0);
      }
    }
  }

  getMocks = (new_format) => {
    API({
      method: "GET",
      url: "mock/result?new_format=" + new_format,
    })
      .then((data) => {
        this.setState({ data: data.data.result });
      })
      .catch(() => {});
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      let currentSection = "";
      switch (tab) {
        case 1:
          currentSection = "Speaking Section";
          break;
        case 2:
          currentSection = "Writing Section";
          break;
        case 3:
          currentSection = "Reading Section";
          break;
        case 4:
          currentSection = "Listening Section";
          break;
        case 5:
          // code block
          break;
        default:
        // code block
      }
      this.setState({ activeTab: tab, currentSection: currentSection });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>20task, Empowering your Institute.</title>
        </Helmet>
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Mock Result Analysis</CardTitle>
                  {this.state.activeTab !== 5 && (
                    <Link
                      to={"/admin/progress/" + this.state.activeTab + "?mock=1"}
                    >
                      <Button>
                        Score Analysis of {this.state.currentSection}
                      </Button>
                    </Link>
                  )}
                </CardHeader>
                <CardBody>
                  <div>
                    {this.props.location.pathname !==
                      "/admin/results" && (
                      <Nav
                        className="nav-pills-primary nav-pills-icons justify-content-center"
                        pills
                        role="tablist"
                        tabs
                      >
                        <NavItem>
                          <NavLink1
                            className={
                              this.state.activeTab === 1 ? "active" : ""
                            }
                            onClick={() => {
                              this.toggle(1);
                            }}
                          >
                            Speaking
                          </NavLink1>
                        </NavItem>
                        <NavItem>
                          <NavLink1
                            className={
                              this.state.activeTab === 2 ? "active" : ""
                            }
                            onClick={() => {
                              this.toggle(2);
                            }}
                          >
                            Writing
                          </NavLink1>
                        </NavItem>
                        <NavItem>
                          <NavLink1
                            className={
                              this.state.activeTab === 3 ? "active" : ""
                            }
                            onClick={() => {
                              this.toggle(3);
                            }}
                          >
                            Reading
                          </NavLink1>
                        </NavItem>
                        <NavItem>
                          <NavLink1
                            className={
                              this.state.activeTab === 4 ? "active" : ""
                            }
                            onClick={() => {
                              this.toggle(4);
                            }}
                          >
                            Listening
                          </NavLink1>
                        </NavItem>
                        <NavItem>
                          <NavLink1
                            className={
                              this.state.activeTab === 5 ? "active" : ""
                            }
                            onClick={() => {
                              this.toggle(5);
                            }}
                          >
                            Full Mock Test
                          </NavLink1>
                        </NavItem>
                      </Nav>
                    )}
                    {this.props.location.pathname ===
                      "/admin/results" && (
                      <TopNavPills
                        activeTab={this.state.activeTab}
                        toggle={this.toggle}
                      />
                    )}
                  </div>
                  <div className="mockResultOuter">
                    <Table className="mock-open">
                      <thead className="text-primary">
                        <tr>
                          <th className="">Title</th>

                          <th className="">Attempted at</th>
                          <th className="">Action</th>
                          <th className="">Scorecard</th>
                          <th className="">Analysis</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.map((val, key) => {
                          var score = val.total;
                          var now = new Date();
                          var today = new Date(now.toUTCString().slice(0, -4));
                          var up = new Date(val.updated_at);
                          var diffMs = today - up;
                          var minutes = Math.floor(diffMs / 1000 / 60);
                          if (
                            val.mock &&
                            val.mock.category == this.state.activeTab
                          ) {
                            return (
                              <tr key={key}>
                                <td className="">
                                  {val.mock.title ? val.mock.title : ""}
                                </td>
                                <td className="">
                                  {val.aus_date ? val.aus_date : ""}
                                </td>
                                <td className="">
                                  {score ? (
                                    <NavLink
                                      to={"/admin/result/" + val.id}
                                      className="nav-link"
                                      activeClassName="active"
                                    >
                                      <i className="fa fa-link" />
                                      <p>View Feedback</p>
                                    </NavLink>
                                  ) : (
                                    "Please Wait..."
                                  )}
                                  {/* {(minutes > 20)?<NavLink
                                                        to={'/admin/result/'+val.id}
                                                        className="nav-link"
                                                        activeClassName="active"
                                                      >
                                                        <i className="" />
                                                        <p>View Feedback</p>
                                                      </NavLink>:"Please Wait..."} */}
                                </td>
                                <td>
                                  {score ? (
                                    <NavLink
                                      to={"/admin/scorecard/" + val.id}
                                      className="nav-link"
                                      activeClassName="active"
                                    >
                                      <i className="fa fa-link" />
                                      <p>View</p>
                                    </NavLink>
                                  ) : (
                                    "-"
                                  )}
                                  {/* {(minutes > 20)?<NavLink
                    to={'/admin/scorecard/'+val.id}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className="" />
                    <p><i className="fa fa-link"></i></p>
                  </NavLink>:"-"} */}
                                </td>
                                <td>
                                  {score ? (
                                    <NavLink
                                      to={"/admin/analysis/" + val.id}
                                      className="nav-link"
                                      activeClassName="active"
                                    >
                                      <i className="fa fa-link" />
                                      <p>View</p>
                                    </NavLink>
                                  ) : (
                                    "-"
                                  )}
                                  {/* {(minutes > 20)?<NavLink
                                          to={'/admin/analysis/'+val.id}
                                          className="nav-link"
                                          activeClassName="active"
                                        >
                                          <i className="" />
                                          <p><i className="fa fa-link"></i></p>
                                        </NavLink>:""} */}
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default MockResult;
