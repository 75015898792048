import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Alert
} from "reactstrap";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

class Material extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      redirect:false,
      type:0,
      all_ok:true,
      isPractice:true,
    };
    this.props.history.push(this.props.match.url);
  }
  

  showQuestion(type) {
    this.setState({
      redirect: true,
      type: type
    });
  }
 
  render() {
    if (this.state.redirect) {
      return <Redirect to={'/admin/questionList/0/'+this.state.type} />;
    }

    return (
      <>
        <Helmet>
          <title>20task, Empowering your Institute.</title>
        </Helmet>

        {!this.state.all_ok && <ChromeCheck />}
        {this.state.all_ok && 
          <div className="content multipleCards">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Speaking Zone
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                     <Col onClick={this.showQuestion.bind(this,1)} xs={3}><Alert color="primary">Read Aloud</Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,2)} xs={3}><Alert color="primary">Repeat Sentence</Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,3)} xs={3}><Alert color="primary">Describe Image</Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,4)} xs={3}><Alert color="primary">Re-tell Lecture</Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,5)} xs={3}><Alert color="primary">Answer Short Questions</Alert></Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Writing Zone</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                     <Col onClick={this.showQuestion.bind(this,6)} xs={3}><Alert color="warning">Summarize written text</Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,7)} xs={3}><Alert color="warning">Write Essay</Alert></Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Reading Zone</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                     <Col onClick={this.showQuestion.bind(this,8)} xs={3}><Alert color="info">Multiple Type, Single Answer </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,9)} xs={3}><Alert color="info">Multiple Type, Double Answer </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,10)} xs={3}><Alert color="info">Reorder Paragraph </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,11)} xs={3}><Alert color="info">Reading Fill in the Blanks </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,12)} xs={3}><Alert color="info">Fill in the Blanks Reading & Writing </Alert></Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Listening Zone</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                     <Col onClick={this.showQuestion.bind(this,13)} xs={3}><Alert color="success">Summarize Spoken text </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,14)} xs={3}><Alert color="success">MCQ Single answer </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,15)} xs={3}><Alert color="success">MCQ multiple answer </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,16)} xs={3}><Alert color="success">Listening Fill In the Blanks </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,17)} xs={3}><Alert color="success">Highlight correct summary </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,18)} xs={3}><Alert color="success">Select Missing Word </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,19)} xs={3}><Alert color="success">Highlight Incorrect word </Alert></Col>
                     <Col onClick={this.showQuestion.bind(this,20)} xs={3}><Alert color="success">Write from Dictation </Alert></Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        }        
      </>
    );
  }
}

export default Material;
