import React from 'react';
import { Link,Redirect} from 'react-router-dom';
import Header from '../components/Frontend/Header';
import Footer from '../components/Frontend/Footer';
import FameWallPte from '../components/Frontend/FameWallPte';
import Testimonial from '../components/Frontend/Testimonial';
import WhyLove from '../components/Frontend/WhyLove';
import SimpleReactValidator from 'simple-react-validator';
import {store} from 'store/index';
import { setAlert} from 'action/index.js';
import API from "api.js";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Pte extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        email: '',
        phone:'',
        course:'IELTS Online',
        lang:'Hindi',
        query:'',
        flag:false,
        loginLock:false,
        readMoreFlag:false
      };
      this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.pageView();
    }
    submitForm = (e) => {
      e.preventDefault();
      this.hitApi();
    }

    changeReadMore = () =>{
      this.setState({readMoreFlag:!this.state.readMoreFlag})
    }

    hitApi = async() => {
      if (this.state.loginLock) {
        return ;
      }
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        return ;
      }
      this.setState({loginLock:true});
      
      var bodyFormData = new FormData();
      bodyFormData.set('email', this.state.email);
      bodyFormData.set('name',this.state.name);
      bodyFormData.set('phone',this.state.phone);
      bodyFormData.set('message','<b>course:</b> '+this.state.course+'<br/><b>Query:</b> '+this.state.query);
      bodyFormData.set('data', 'email: '+ this.state.email+',name: '+this.state.name+',phone: '+this.state.phone+',course: '+this.state.course+',query: '+this.state.query);
      bodyFormData.set('page', "IELTS Online coaching");
      let res = null;
      try{
        res = await API({
          method: 'POST',
          url: 'sendOnlinePte',
          data: bodyFormData
        })
      }catch(error) {

          
      };
      console.log(res)
      if (res?.status === 200) {
        store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.message}));
        this.setState({name:'',email:'',phone:'',course:'PTE On-Campus',query:'',loginLock:false,flag:true})
      }else{
        this.setState({loginLock:false});
      }
      
    
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  }
 render(){ 
    if (this.state.flag) {
      return (<Redirect to="/thank-you" />);
    }  
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Australia’s Best IELTS Course Online – Designed to achieve 8+ band score</title>
        <meta name="twitter:title" content="Australia’s Best IELTS Course Online – Designed to achieve 8+ band score" />
        <meta property="og:title" content="Australia’s Best IELTS Course Online – Designed to achieve 8+ band score" />
        <meta name="description" content="The IELTS (International English Language Testing System) assesses the English language capabilities of the non-native English speakers who wish to obtain an international certificate recognizing their ability." />      
        
        <meta name="twitter:description" content="The IELTS (International English Language Testing System) assesses the English language capabilities of the non-native English speakers who wish to obtain an international certificate recognizing their ability." />
        <meta property="og:description" content="The IELTS (International English Language Testing System) assesses the English language capabilities of the non-native English speakers who wish to obtain an international certificate recognizing their ability." />
    </Helmet>
    <div className="innerPage pteOnline ieltsClasses">
    <Header />
    
    <section class="bannerOuter">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1>Australia’s Best IELTS Course Online – Designed to achieve 8+ band score</h1>
            <Link to="/contact" className="themeBtn">Contact us</Link>
            <Link to="" className="themeBtn">Enrol Now</Link>
          </div>
        </div>
      </div>
    </section>
<section class="ieltsTraining">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2 class="title">What is <span>IELTS?</span></h2>
        <p>The IELTS (International English Language Testing System) assesses the English language capabilities of the non-native English speakers who wish to obtain an international certificate recognizing their ability. IELTS checks the Reading, Writing, Listening, and Speaking abilities by scoring on a band of 0 to 9.</p>
        <p>There are two modules IELTS General and IELTS Academic. While the General test is easier (the average minimum score to qualify it is 5.5, it helps in migrating on the basis of sponsored or skilled migration or taking admission to a vocational course), Academic test, on the contrary, is rather complex comparatively (the average minimum is 6.5 and helps in meeting the academic requirements for taking admission in an undergraduate, graduate, post-graduate and doctorate courses).</p>
        <p>The test taker can apply to appear on any available schedule worldwide. They need not bother about first finishing their IELTS Coaching Online.</p>
        {!this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text" >Read More</p>}
        {this.state.readMoreFlag && <React.Fragment>
        <h5>Why IELTS?</h5>
        <p>IELTS qualified candidates get an internationally accepted certificate that is recognized by various English-speaking nations, including Australia. Qualifying this test with the average minimum is necessary in order to request engagement at any professional or higher educational institute.</p>
        <p>This certificate for the academic module is valid for two years, but for the general module, it has added one-year validity.</p>

        <h5>Why take IELTS training online?</h5>
        <p>Success at IELTS is more than just having excellent English skills! In case any other essential commitments are stopping you from attending an on-campus IELTS Coaching, then you can take IELTS training online. Enrol in an IELTS training online by 20 Task and upscale your English Linguistic skills!</p>

        <h5>What all does the IELTS Course Online at 20 Task Includes?</h5>
        <p>The goal of our IELTS training online is to improve your English language abilities for unleashing your potential to achieve the highest possible score in the exam. Our IELTS Course Online makes the students proficient in English Linguistics. The strategies and courses for both online and offline programs are the same. The only difference remains in the lecture delivery mode. We devise IELTS Course modules to focus on improving all the aspects (Writing, Reading, Listening, and Speaking) of the student.</p>
        <p><strong>The IELTS training online at 20 Task is excellent. It helps to prepare the best with:</strong></p>
        <ul class="checklist">
          <li>$0 enrolment fee.</li>
          <li>ALL IELTS preparation materials (100% FREE)</li>
          <li>4 ASSESSMENTS worth $200 (100% FREE)</li>
          <li>100+ hours of Coaching and Assessments.</li>
          <li>The test can be arranged on other days as well</li>
          <li>Students are free to stay in the institute all day long and practice for their exam.</li>
          <li>Unlimited Practice at Institute! Mon-Sun (100% FREE)</li>
          <li>Personal and Private Feedback</li>
          <li>STRATEGIES + TECHNIQUES for All 4 IELTS Modules</li>
          <li>4 X 15 minutes Private Consultation with IELTS Expert</li>
          <li>Assistance in booking IELTS Exam</li>
          <li>Weekend Course Also Available</li>
          <li>PROPER COMPUTER LAB FOR PRACTICING MOCK IELTS EXAM!</li>
        </ul>
        </React.Fragment>}
        {this.state.readMoreFlag && <p onClick={(e)=>{this.changeReadMore()}} className="readmore_text">Read Less</p>}
      </div>
    </div>
  </div>
</section>
<section class="practicePortal">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 class="title">Why choose <span>20 Task IELTS Training</span></h2>
        <div class="row whyloveCntnt">
          <div class="col-sm-4">
            <img src="images/experienced-trainer.png" alt="experienced-trainer" />
            <h3>Experienced Trainer</h3>
            <p>They are adept at both Linguistics skills and imparting the educational lectures online.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/star-ribben.png" alt="star-ribben" />
            <h3>Highest Success Rate</h3>
            <p>With detailed and well-planned online learning modules, we are able to give the best training to our online students, and they are constantly achieving 8+ band scores.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/book.png" alt="book" />
            <h3>Free Intensive Material</h3>
            <p>Our templates make it easier for any online student to self-learn quickly from the framework and follow them to achieve the best scores.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/settings.png" alt="settings" />
            <h3>Latest Resources</h3>
            <p>Our students get access to our online learning resources.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Online Support</h3>
            <p>Our experts are always for any online student. You can reach out to any trainer and ask questions or seek help in solving any doubts that you have.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Past Exam questions to Practice</h3>
            <p>We have a repository of past exam questions with answers to practice from that can help any student to quickly practice the previous year’s questions.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Discussion Sessions</h3>
            <p>Our faculties carefully assess the level of the students during the online discussion sessions and suggest the personalized study plan.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Affordable Fees</h3>
            <p>We have kept the online course fees so less so that every student can easily afford to pay the costs on their own.</p>
          </div>
          <div class="col-sm-4">
            <img src="images/support.png" alt="support" />
            <h3>Real Time Test Environment</h3>
            <p>We assess all our online students from time to time and give them a real-time test environment, so they acclimate to the actual test environment and feel less stressed during the actual test.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="getWithUs greyBg">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2 class="title text-center">What You Will <span>Get With Us</span></h2>
        <div class="row">
          <div class="col-sm-8">
            <div class="ourFeatures">
              <ul class="checklist">
                <li>Unlimited Classes</li>
                <li>Unlimited Writing & Speaking Assessments</li>
                <li>1 to 1 Tutorial with Tutor</li>
                <li>Practice Questions</li>
                <li>Method Lessons</li>
                <li>Overview Classes</li>
                <li>Pre-Test Strategy Sessions</li>
                <li>Post-Test Consultations</li>
              </ul>
            </div>
          </div>
          <div class="col-sm-4 text-center">
            <div class="naatiCoaching">
              <h3>Take a FREE Demo Class of Online IELTS Coaching</h3>
              <form onSubmit={this.submitForm}>
                <div class="fieldOuter">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name*"
                    value={this.state.name}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Name', this.state.name, 'required')}
                </div>
                <div class="fieldOuter">
                  <input
                    type="email" 
                    name="email"
                    placeholder="Email*"
                    value={this.state.email}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Email', this.state.email, 'required')}
                </div>
                <div class="fieldOuter">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone*"
                    value={this.state.phone}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Phone', this.state.phone, 'required')}
                </div>
                <div class="fieldOuter">
                  <select
                    name="course"
                    value={this.state.course}
                    onChange={(e) => { this.changeInput(e)} }
                  >
                                <option value="IELTS Online">IELTS Online</option>
                                <option value="PTE On-Campus">PTE On-Campus</option>
                                <option value="NAATI CCL Online">PTE Online</option>
                                <option value="NAATI CCL On-Campus">NAATI CCL On-Campus</option>
                                <option value="NAATI CCL Online">NAATI CCL Online</option>
                                <option value="IELTS On-Campus">IELTS On-Campus</option>
                              </select>
                </div>
                <div class="fieldOuter">
                  <input
                    type="text"
                    name="query"
                    placeholder="Comments/Query"
                    value={this.state.query}
                    onChange={(e) => { this.changeInput(e)} }
                  />
                  {this.validator.message('Query', this.state.query, 'required')}
                </div>
                <div class="fieldOuter">
                  {!this.state.loginLock && <button class="themeBtn" onClick={(e)=>{this.hitApi()}}>Send Message</button>}
                  {this.state.loginLock && <button class="themeBtn" >Wait...</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
		
    	<Footer />
      </div>
    </React.Fragment>);
    }
}

export default Pte;