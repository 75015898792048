import React from 'react';
import {
	CardHeader,
	CardTitle,
	Row, Col,
	Badge
} from 'reactstrap';
import API from 'api.js';
import PracticeMainTimer from "components/NewPractice/PracticeMainTimer";

export default function PracticeHeader({
	questionData,
	type,
	prediction,
	aType,
	mark,
	isAttempted,
	hideNav,
	currentQuestion,
	totalQuestions,
	restartQuestion,
	updateQuestionData
}) {

	const changeInput = (e) => {
		let { name, value } = e.target;
		
		const bodyFormData = new FormData();
		bodyFormData.set('question_id', questionData[0].id);
		bodyFormData.set('tag', value);
		bodyFormData.set('id', type);
		bodyFormData.set('prediction', prediction);
		bodyFormData.set('type', aType);
		bodyFormData.set('mark', mark);

		API({
			method: 'POST',
			url: 'set/tag',
			data: bodyFormData
		}).then(data => {
			let items = questionData;
			items[0] = {
				...questionData[0],
				tag: [
					{ tag: value }
				]
			};
			updateQuestionData(items);
		});
	}

	const tagColor = () => {
		return (questionData[0].tag.length > 0) ? questionData[0].tag[0].tag : "grey";
	}

	const questionBadges = () => {
		if (questionData.length > 0) {
			return (
				<>
					{questionData[0].is_weekly_prediction === 1 &&
						<Badge color="primary">Monthly Prediction</Badge>
					}

					{questionData[0].is_exam_question === 1 &&
						<Badge color="primary">Exam Question</Badge>
					}

					{questionData[0].complexity === 0 &&
						<Badge color="primary">Easy</Badge>
					}

					{questionData[0].complexity === 1 &&
						<Badge color="primary">Medium</Badge>
					}

					{questionData[0].complexity === 2 &&
						<Badge color="primary">Difficult</Badge>
					}

					{isAttempted &&
						<Badge color="success">Attempted</Badge>
					}
				</>
			);
		}
		return "";
	}

	const currentQuestionLabel = () => {
		return (questionData.length > 0) ? questionData[0].q_title : "";
	}

	const questionTitle = (subcategory_id) => {
		const titles = [
			"Look at the text below. In the 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 35 seconds to read aloud.",
			"You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.",
			"Look at the Image below. In 25 seconds,please speak into the microphone and describe in detail what the graph showing. You will have 40 seconds to give your response.",
			"You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.",
			"You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.",
			"Read the passage and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. your response will be judge on the quality of your writing and on how well your response presents the key points in the passage.",
			"You will have 20 minutes to plan, write and revise an essay about the topic below. your response will be judged on how well you develop a position, oraganize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.",
			"Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.",
			"Read the text and answer the question by selecting all the correct responses. More than one response is correct.",
			"The textbox in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.",
			"In the text below some words are missing. Drag words from the text box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.",
			"Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.",
			"You will hear a short lecture. Write a summary for a fellow student who was not present at the lecture. You should wirte 50-70 words. You have 10 minutes to finish the task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.",
			"Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.",
			"Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.",
			"You will hear a recording. Type the missing words in each blank.",
			"You will hear a recording. Click on the paragraph that best relates to the recording.",
			"You will hear a recording about "+ currentQuestionLabel() +". At the end of the recording the last word or group of words has benn replaced by a beep. Select the correct option to complete the recording",
			"You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.",
			"You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once."
		];
		return titles[subcategory_id - 1];
	}


	return (
		<CardHeader>
			<Row>
				<Col xs={6}>
					<CardTitle tag="h3">
						{questionData.length > 0 &&
							<>
								{questionData[0].q_title} &nbsp;
								<select
									name="tag"
									onChange={changeInput}
									style={{
										border: '0px',
										fontFamily: 'FontAwesome',
										background: 'white',
										color: tagColor()
									}}
								>
									<option value="grey" style={{color:'grey'}}>&#xf02c;</option>
									<option value="red" style={{color:'red'}}>&#xf02c;</option>
									<option value="green" style={{color:'green'}}>&#xf02c;</option>
									<option value="yellow" style={{color:'yellow'}}>&#xf02c;</option>
								</select>
							</>
						}
					</CardTitle>

					{questionBadges()}
				</Col>

				<Col xs={6}>
					<div className="text-right questionTime">
						<PracticeMainTimer
							restartQuestion={restartQuestion}
						/>
						<div className="item">
							{!hideNav &&
								<p>Item {currentQuestion} of {totalQuestions}</p>
							}
						</div>
					</div>
				</Col>
			</Row>
			{questionTitle(type)}
		</CardHeader>
	);
}