/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
import API,{base} from "../../api.js";
class Testimonial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list:[]
        }
    }
    componentDidMount() {
        API({
              method: 'GET',
              url: "testimonial/list",
              data:'json',
            }).then(data => {
              this.setState({list:data.data.response.data});
            }).catch(()=>{
                this.setState({lockSubmit:false});
            });
    }
 render(){
	return (
	<section className="testimonials">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h2 className="title">Student <span>Testimonials</span></h2>
                    <Slider className="testiContent regular02 slider">
                        {this.state.list.map((item)=>{
                            return (<div className="testiBlock">
                            <div className="imgOuter">
                                <img src={base+item.image} alt="testimonial" />
                            </div>
                            <h3>{item.name}</h3>
                            <p>
                               {item.message} 
                            </p>
                        </div>);
                        })}
                    </Slider>
                    <Link to="/auth/register" className="themeBtn">Enrol now</Link>
                </div>
            </div>
        </div>
    </section>
	);
}
}

export default Testimonial;