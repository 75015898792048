/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
class FameWallPte extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false
        }
    }


    componentDidMount() {
        
    }
 render(){
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1
    };
	return (
		<section className="fameWall">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className="title">Score Cards of our IELTS students </h2>
                        
                        <Slider {...settings} classNameName="fameSlider regular slider">
                            <div>
                              <img src="https://testing.languageacademy.com.au/images/IELTS/1.jpg" />
                            </div>
                            <div>
                              <img src="https://testing.languageacademy.com.au/images/IELTS/2.jpg" />
                            </div>
                            <div>
                              <img src="https://testing.languageacademy.com.au/images/IELTS/3.jpg" />
                            </div>
                            <div>
                              <img src="https://testing.languageacademy.com.au/images/IELTS/4.jpg" />
                            </div>
                            <div>
                              <img src="https://testing.languageacademy.com.au/images/IELTS/5.jpg" />
                            </div>
                            <div>
                              <img src="https://testing.languageacademy.com.au/images/IELTS/6.jpg" />
                            </div>
                            <div>
                              <img src="https://testing.languageacademy.com.au/images/IELTS/7.jpg" />
                            </div>
                            <div>
                              <img src="https://testing.languageacademy.com.au/images/IELTS/8.jpg" />
                            </div>
                            <div>
                              <img src="https://testing.languageacademy.com.au/images/IELTS/9.jpg" />
                            </div>
                            
                        </Slider>
                        <Link to="/scorecard"><button className="themeBtn">Visit our Wall of Fame</button></Link>
                    </div>
                </div>
            </div>
        </section>
	);
}
}

export default FameWallPte;