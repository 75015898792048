import React, { useState, useEffect } from 'react';
import Waiting from 'components/NewPractice/Speaking/ReadAloud/Waiting';
import Recording from 'components/NewPractice/Speaking/ReadAloud/Recording';

export default function AudioBox({
	questionData,
	updateListeningState,
	listening,
	listening_finish
}) {

	const [isWaiting, setIsWaiting] = useState(true);

	const changeWaitStatus = () => {
		setIsWaiting(false);
	}

	return (
		<>
			<div className="audio-box">
				<h4>Recorded Answer</h4>
				<div>
					<p><strong>Current status: </strong></p>
					{isWaiting &&
						<Waiting
							changeWaitStatus={changeWaitStatus}
						/>
					}
					{!isWaiting &&
						<Recording
							updateListeningState={updateListeningState}
							listening_finish={listening_finish}
							listening={listening}
						/>
					}
				</div>
			</div>
		</>
	);
}