/*global chrome*/
import React from 'react';
import { Link} from 'react-router-dom';
import API,{base} from "../../api.js";
import Slider from "react-slick";
class VideoTuts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false,
            list:[]
        }
    }

    componentDidMount() {
      
    }
 render(){
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            infinite: true,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
	return (
		<section className="tutorialVd">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className="title">{this.props.title}</h2>
                        <div className="videosCntnt">
                            <Slider {...settings} classNameName="testiContent regular02 slider">
                                
                            <div className="videoBlk">
                                <div className="videoOuter">
                                    <iframe src="https://www.youtube.com/embed/ib-ixzMIxB0" />
                                </div>
                                <p></p>
                            </div> <div className="videoBlk">
                                <div className="videoOuter">
                                    <iframe src="https://www.youtube.com/embed/vZMVTmsCmjc" />
                                </div>
                                <p></p>
                            </div>
                            <div className="videoBlk">
                                <div className="videoOuter">
                                    <iframe src="https://www.youtube.com/embed/pJLaXWI1AqI" />
                                </div>
                                <p></p>
                            </div>
                            <div className="videoBlk">
                                <div className="videoOuter">
                                    <iframe src="https://www.youtube.com/embed/r2er4KkdWUk" />
                                </div>
                                <p></p>
                            </div>
                            <div className="videoBlk">
                                <div className="videoOuter">
                                    <iframe src="https://www.youtube.com/embed/bOay9ScIfGM" />
                                </div>
                                <p></p>
                            </div>
                            
                            </Slider>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
	);
}
}

export default VideoTuts;