import React, { useState } from 'react';
import CurrentStatus from 'components/NewPractice/Speaking/RetellLecture/Waiting';
import QuestionAudio from 'components/NewPractice/Speaking/RetellLecture/QuestionAudio';
import RecordinBox from 'components/NewPractice/Speaking/RetellLecture/RecordinBox';

export default function Question({
	resultMode,
	questionData,
	listening,
	listening_finish,
	updateListeningState
}) {

	const [waiting, setWaiting] = useState(true);
	const [playQuestionAudio, setPlayQuestionAudio] = useState(false);
	const [recordingWait, setRecordingWait] = useState(false);
	const [recording, setRecording] = useState(false);
	const [recordingStartsIn, setRecordingStartsIn] = useState(0);

	const updateRecordingStartsIn = (duration) => {
		setRecordingStartsIn(duration);
	}

	const updateRecordingStatus = (status) => {
		setRecording(status);
	}

	const updateRecordingWait = (status) => {
		setRecordingWait(status);
		if (!status) {
			setRecording(true);
		}
	}

	const updateWaitingStatus = (status) => {
		setWaiting(status);

		// play question audio.
		if (!status) {
			updateQuestionAudioStatus(true);
		}
	}

	const updateQuestionAudioStatus = (status) => {
		setPlayQuestionAudio(status);
		if (!status) {
			updateRecordingWait(true);
		}
	}

	return (
		<>
			{!resultMode &&
				<div>
					<div className="currentStatus">
						<div>
							Current Status: {waiting ? (
								<CurrentStatus updateWaitingStatus={updateWaitingStatus} />
							) : (
								<>
									{playQuestionAudio ? "Playing" : ""}
								</>
							)}
							<br></br>
							<QuestionAudio
								updateQuestionAudioStatus={updateQuestionAudioStatus}
								questionAudio={questionData[0].media_link}
								playQuestionAudio={playQuestionAudio}
								updateWaitingStatus={updateWaitingStatus}
								updateRecordingStartsIn={updateRecordingStartsIn}
							/>
						</div>
					</div>

					<RecordinBox
						recordingWait={recordingWait}
						recordingStartsIn={recordingStartsIn}
						listening={listening}
						listening_finish={listening_finish}
						updateListeningState={updateListeningState}
						waiting={waiting}
					/>
				</div>
			}
		</>
	);
}