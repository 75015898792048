import React from "react";
import { Link } from "react-router-dom";

class Unauthorized extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-md-6 text-center">
            <h1 className="mb-3">Unauthorized Access</h1>
            <p>You can not view this result page.</p>
            <div>
              <Link
                to="/admin/dashboard"
                className="btn btn-primary"
                style={{ backgroundColor: "rgb(22, 37, 69)" }}
              >
                Go to dashboard
              </Link>
              <Link
                to="/admin/mock-result"
                className="btn btn-primary"
                style={{ backgroundColor: "rgb(22, 37, 69)" }}
              >
                Go to results page
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Unauthorized;
