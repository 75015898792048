import React, { useState, useEffect } from 'react';
import { Progress } from 'reactstrap';

export default function ProgressComponent({
	recording,
	stop
}) {
	const RECORDLIMIT = 35;
	const [recorded, setRecorded] = useState(0);
	const [intervalId, setIntervalId] = useState(0);

	useEffect(() => {
		let allow_increament = true;
		const i = setInterval(() => {
			if (allow_increament) {
				setRecorded(recorded => (recorded + 1));
			}
		}, 1000);
		setIntervalId(i);

		return (() => {
			allow_increament = false;
		});
	}, []);

	useEffect(() => {
		if (!recording) {
			recordingComplete();
		} else if (recorded === RECORDLIMIT) {
			stop();
		}
	}, [recording, recorded]);

	const recordingComplete = () => {
		clearInterval(intervalId);
		setRecorded(RECORDLIMIT);
	}

	return (
		<Progress color="info" value={recorded * 3} />
	);
}