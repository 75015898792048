import React, { useState, useEffect } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalFooter
} from 'reactstrap';
import API from 'api.js';
import { store } from "store/index";
import { setAlert } from "action/index.js";

export default function QuestionTestedButton({
	questionData
}) {
	const [testedCount, setTestedCount] = useState(0);
	const [testModal, setTestModal] = useState(false);

	useEffect(() => {
		setTestedCount(questionData[0].tested);
	}, []);

	const testedFunc = () => {
		setTestModal(!testModal);
	}

	const handleYes = () => {
		const bodyFormData = new FormData();
		bodyFormData.set('question_id', questionData[0].id);

		API({
			method: 'POST',
			url: 'tested/exam',
			data: bodyFormData
		}).then(data => {
			store.dispatch(setAlert({
				flag: true,
				type: 1,
				message: data.data.message
			}));

			setTestedCount(data.data.count);
			setTestModal(false);
		}).catch((err) => {
			setTestModal(false);
		});
	}

	return (
		<>
			<Button
				color="info"
				onClick={testedFunc}
				className="bottom-btn"
				size="sm"
			>{testedCount} x tested</Button>

			<Modal isOpen={testModal} size="lg">
				<ModalHeader>Do you have seen this question in exam ?</ModalHeader>
				<ModalFooter>
					<Button
						color="secondary"
						className="modalCloseBtn"
						onClick={handleYes}
					>Yes</Button>
					
					<Button 
						color="secondary"
						className="modalCloseBtn"
						onClick={testedFunc}
					>No</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}