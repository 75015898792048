import React, { useState, useEffect } from "react";

import {
	Col,
	Row,
	Progress,
	Button,
	Modal,ModalBody,ModalFooter,ModalHeader
} from "reactstrap";
import SpeechRecognition from 'react-speech-recognition';
import Dictaphone from "./Dictaphone.jsx";
import getBlobDuration from 'get-blob-duration';
import { s3base } from "api.js";
import MicRecorder from 'mic-recorder-to-mp3';
import "components/custom.css";
import Beep from 'components/Beep/Beep.jsx';
import { confirmAlert } from 'react-confirm-alert';
let Mp3Recorder = new MicRecorder({ bitRate: 128 });

const COUNTDOWN = 25;
const RECORDLIMIT = 40;

export default function DescribeImageNew(props) {
	const [countdown, setCountdown] = useState(COUNTDOWN);
	const [countdownId, setCountdownId] = useState(null);
	const [recordStatus, setRecordStatus] = useState(false);
	const [recordDuration, setRecordDuration] = useState(0);
	const [recordId, setRecordId] = useState(null);
	const [blobURL, setBlobUrl] = useState('');
	const [reset, setReset] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);
	const [imagePath, setImagePath] = useState('');
	const [beepStatus, setBeepStatus] = useState(false);
	const [file, setFile] = useState('');
	const [trans, setTrans] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);

	const beepFinished = () => {
		setBeepStatus(false);

		if (!isBlocked) {
			SpeechRecognition.startListening({ continuous: true, language: 'en-GB' });
			Mp3Recorder = new MicRecorder({ bitRate: 128 });
			Mp3Recorder.start().then(() => {
				beginRecording();
			}).catch((e) => console.error(e));
		}
	}

	const [beepProps, setBeepProps] = useState({
		status: false,
		beepFinished
	});
	// const toggleModal = () => {
	// 	setModalOpen(prevModalOpen => !prevModalOpen);
	//   };
	const checkMedia = () => {
		navigator.getUserMedia({ audio: true }, () => {
			setIsBlocked(false);
		}, () => {
			setIsBlocked(true);
		});
	}

	const beginCountdown = () => {
		const countdown_id = setInterval(() => {
			setCountdown(countdown => countdown - 1);
		}, 1000);
		setCountdownId(countdown_id);
	}

	const beginRecording = () => {
		const record_id = setInterval(() => {
			setRecordDuration(recordDuration => recordDuration + 1);
		}, 1000);
		setRecordStatus(true);
		setRecordId(record_id);
		setBlobUrl('');
	}

	const handleImageLoaded = () => {
		setIsLoading(false);
		beginCountdown();
	}

	const stopCountDownAndBeginRecording = () => {
		clearInterval(countdownId);
		setCountdownId(null);
		setBeepStatus(true);
	}

	const skipCountdown = () => {
		stopCountDownAndBeginRecording();
	}

	const setTransAb = (text) => {
		if (trans !== text) {
			setTrans(text);
		}
	}

	const getDuration = async (blob) => {
		let duration = 0;
		duration = await getBlobDuration(blob).then((d) => {
			return d;
		}).catch(async (e) => {
			return await getBlobDuration(URL.createObjectURL(blob));
		});
		return duration;
	}

	const getFile = (blob, buffer, filename) => {
		let file = null;
		try {
			file = new File([blob], filename, { type: blob.type });
		} catch (err) {
			file = new File(buffer, filename, {
				type: blob.type,
				lastModified: Date.now()
			});
		}
		return file;
	}

	const getBlobURL = (blob, file) => {
		let url = null;
		try {
			url = URL.createObjectURL(file);
		} catch (err) {
			url = URL.createObjectURL(blob);
		}
		return url;
	}
const deleteComment = () =>{
    
		confirmAlert({
		  title: '',
		  message: 'Sorry!! We were unable to detect your voice !',
		  buttons: [
			{
			  label: 'Would you like to retry?',
			  onClick: () => {props.restartQuestion()}
			},
			{
			  label: 'Continue',
			  onClick: () => {stopRecording()}
			}
		  ]
		});
	  }
	
	const stopRecording = () => {
		if (!isBlocked && recordStatus) {
			setTimeout(() => {
				Mp3Recorder.stop().getMp3().then(async ([buffer, blob]) => {

					const duration = await getDuration(blob);
					const file = getFile(blob, buffer, `noname1${props.data.id}.mp3`);
					const blobURL = getBlobURL(blob, file);
					console.log("Audio Text:", trans);
					// const blobURL = URL.createObjectURL(blob);
					// let duration = await getBlobDuration(blobURL);
					// let file = new File([blob], `noname1${props.data.id}.mp3`, { type: blob.type });
					SpeechRecognition.stopListening();

					setBlobUrl(blobURL);
					setRecordStatus(false);
					setRecordDuration(100);
					setFile(file);

					props.submit({
						data: file,
						audio_text: trans,
						duration,
					}, true);
					props.showResult();
				}).catch((e) => console.log(e));
			}, 100);
		}


		/**if(recordDuration>5){
			if(!trans){
				deleteComment();
				return;
			}
		}*/
	}
	
	/**
	 * DidMount hook.
	 */
	useEffect(() => {
		init();

		//compoent will unmount
		return () => {
			setImageLoaded(false);
			if (countdownId !== null) {
				clearInterval(countdownId);
			}
			if (recordId !== null) {
				clearInterval(recordId);
			}
		}
	}, []);


	/**
	 * Load Image then assign loaded status to true.
	 * */
	useEffect(() => {
		if (imageLoaded === false) {
			const image_src = s3base + props.data.media_link;
			const image = new Image();
			image.src = image_src;
			image.onload = () => {
				setImagePath(image_src);
				setImageLoaded(true);
			}
		}
	}, [imageLoaded]);


	/**
	 * Countdown reaches 0.
	 * and begin recording.
	 */
	useEffect(() => {
		if (countdown === 0) {
			stopCountDownAndBeginRecording();
		}
	}, [countdown]);


	/**
	 * Call beep component if beep status is true.
	 */
	useEffect(() => {
		if (beepStatus === true) {
			let beepPropsCopy = {...beepProps};
			beepPropsCopy.status = true;
			setBeepProps(beepPropsCopy);
		}
	}, [beepStatus]);


	/**
	 * Restart Question.
	 */
	useEffect(() => {
		if (props.restart === true) {
			init(true);
		}
	}, [props.restart]);
	

	/**
	 * Clear interval when recordStatus changes to false.
	 */
	useEffect(() => {
		if (recordStatus === false) {
			clearInterval(recordId);
			setRecordId(null);
		}
	}, [recordStatus]);


	useEffect(() => {
		if (recordDuration === RECORDLIMIT) {
			stopRecording();
		}
	}, [recordDuration]);


	/**
	 * Initialize component behavior
	 */
	const init = (restart = false) => {
		checkMedia();

		if (restart) {
			if(countdownId !== null) {
				clearInterval(countdownId);
			}

			setCountdown(COUNTDOWN);
			setCountdownId(null);
			setRecordStatus(false);
			setRecordDuration(0);
			setBeepStatus(false);
			setRecordId(null);
			setBlobUrl('');
			setTrans('');
			setFile('');
			setBeepProps({ status: false, beepFinished });

			beginCountdown();
		}
	}

	return (
		<>
			{recordStatus &&
				<Dictaphone setTrans={setTransAb} status={reset || recordStatus} />
			}
			<Col xs={12}>
				<h4 className="infoText"></h4>
			</Col>

			<Col xs={12}>
				<Row>
					<Col lg={6}>
						{isLoading && (
							<div style={{margin:'145px'}}>Loading...</div>
						)}
						<img alt="media-link" className={isLoading ? "hidd" : ""} width="500" onLoad={() => handleImageLoaded()} src={s3base + props.data.media_link} />
					</Col>
					<div className="col-lg-6 p-0 text-center">
						{!props.resultMode &&
							<div className="audio-box">
								<h4>Recorded Answer</h4>
								<p><strong>Current status:</strong></p>
								<p>
									{blobURL === '' && !recordStatus ? (
											`Recording starts in ${countdown} seconds`
										) : (blobURL === '' && recordStatus ? (
												'Recording Started'
											) : (
												'Recording Finished'
											)
										)

									}
								</p>
								{!props.testMode && blobURL === '' && !recordStatus && 
									<div className='stop-btn'>
										<Button color="success" onClick={() => skipCountdown()} size="sm">Skip</Button>
									</div>
								}
								<hr></hr>
								{!props.testMode && recordStatus && 
									<div className='stop-btn'>
										<Button color="success" onClick={() => stopRecording()} size="sm">Stop</Button>
									</div>
								}
								<hr></hr>
								<Progress color="info" value={(recordDuration * 2.5)} />
								<div className="audo-line">
									<span className="progress-bar-fill-Read intro"></span>
								</div>
							</div>
						}
					</div>
				</Row>
				<Col xs={12}>
					{blobURL && !props.testMode && 
						<audio src={blobURL} controls="controls" />
					}
				</Col>
			</Col>
			<Col xs={12}>
				<h5>{props.data.question}</h5>
			</Col>
			{!props.resultMode &&
				<Beep {...beepProps} />
			}
			 
		</>
	);
}