import React from 'react';
import Header from '../../components/pteportal/Header';
import Footer from '../../components/pteportal/Footer';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
class Refund extends React.Component {
    componentDidMount() {
        ReactGA.initialize('UA-157984080-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactPixel.init('197333187452630');
        ReactPixel.track('Lead');
        ReactPixel.pageView();
    }
 render(){   
    return (
    <React.Fragment>
    <Helmet htmlAttributes>
        <title>Thank You - 20 Task</title>
        <meta name="twitter:title" content="Login - 20 Task" />
        <meta property="og:title" content="Login - 20 Task" />
        <meta name="description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />      
        <meta name="twitter:description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />
        <meta property="og:description" content="Login into your 20 Task account and start practicing for NAATI, PTE and IELTS." />
     </Helmet>
     <section class="header-with-banner">
      <Header active="home" />
    </section>
    <main class="main-content">
        <div class="space-after-nav"></div>
        <section class="inner-page-first-section listening-page-first-section inner-page-banner listening-page-banner py-80">
            <div class="container">
            <div class="row middle_block">
                <div class="col-xl-12 col-lg-12 mb-5">
                <h2 class="main-heading pt-5 text-center">Thank You</h2>
                <div class="breadcum">
                    <ol class="breadcrumb primary-color text-center">
                        <li class="breadcrumb-item"><a class="white-text" href="#">Home</a></li>
                        <li class="breadcrumb-item active">Thank You</li>
                    </ol>
                </div>
                </div>
            </div>
            </div>
        </section>
        <section class="contentOuter mb-0">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-center mt-5">
                        <div class="thankyou-portal-inner">
                            <h2 class="header"><b><i class="fa fa-check-circle" aria-hidden="true"></i> &nbsp;&nbsp;Thank You!</b></h2>
                            <p>Your query is submitted successfully!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <Footer />
    </React.Fragment>);
    }
}

export default Refund;