import React, { useRef, useEffect } from 'react';
import API from 'api.js';

export default function ConnectedRequest({
	question_type
}) {

	const allowed = [1, 2, 3, 4, 5]; // question subcategory_id

	let interval_id = useRef(null);

	useEffect(() => {
		return () => {
			clearInterval(interval_id.current);
		};
	}, []);

	useEffect(() => {
		if (allowed.includes(question_type)) {
			if (interval_id.current !== null) clearInterval(interval_id.current);
			start();
		} else {
			clearInterval(interval_id.current);
		}
	}, [question_type]);

	const start = () => {
		interval_id.current = setInterval(() => {
			// requestUsingAxios();			
			requestUsingFetch();			
		}, 5000);
	}

	const requestUsingFetch = () => {
		fetch('https://la-ping.languageacademy.com.au/connected')
		.then(response => response.json())
		.then(json => console.log(""))
		.catch(error => console.log(""));
	}

	const requestUsingAxios = () => {
		API({
			url: 'https://la-ping.languageacademy.com.au/connected',
			method: 'GET'
		});
	}

	return "";
}