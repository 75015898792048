import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import ProgressComponent from 'components/NewPractice/Speaking/ReadAloud/ProgressComponent';

export default function Recording({
	updateListeningState,
	listening,
	listening_finish
}) {
	const RECORDLIMIT = 35;
	const [recording, setRecording] = useState(true);
	const [recorded, setRecorded] = useState(0);

	useEffect(() => {
		updateListeningState(listening);
	}, []);

	const stop = () => {
		setRecording(false);
		setTimeout(() => {
			updateListeningState(listening_finish);
		}, 1000);
	}

	const recordingStatus = () => {
		return `Recording ${recording ? "Started" : "Finished"}.`;
	}

	return (
		<>
			<p>{recordingStatus()}</p>
			{recording &&
				<div className="stop-btn">
					<Button
						color="success"
						onClick={stop}
						size="sm"
					>Stop</Button>
				</div>
			}
			<ProgressComponent
				recording={recording}
				stop={stop}
			/>
		</>
	);
}