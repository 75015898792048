import React from 'react';
import { Redirect } from "react-router-dom";
class MockPackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slide: 1,
            price:{
                '1':
                    {
                        '1':{'price':169,'plan':21,'duration':'1 Month(s)'},
                        '0':{'price':99,'plan':20,'duration':'1 Month(s)'}
                    },
                '50':
                    {
                        '1':{'price':209,'plan':23,'duration':'1 Month(s)'},
                        '0':{'price':149,'plan':22,'duration':'1 Month(s)'}
                    },
                '99':
                    {
                        '1':{'price':279,'plan':25,'duration':'3 Month(s)'},
                        '0':{'price':199,'plan':24,'duration':'3 Month(s)'}
                    }
                },
            mockCount:{'1':5,'50':10,'99':30},
            isVideo:1,
            paymentForm:false
      
        };
        
    }
    componentDidMount() {
        

    }

    handleChange = (event) => {
            
            this.setState({slide: event.target.value});      
        
      
    }

    changeVideo = (vid) => {
        let isVideo = (this.state.isVideo === 0)?1:0;
        this.setState({isVideo: vid});
    }

    buyPlan = () =>{
        let plan = this.state.price[this.state.slide][this.state.isVideo].plan;
        this.setState({paymentForm:plan});
    }
 render(){ 
    if(this.state.paymentForm){
        return (<Redirect to={`sub/payment/form/${this.state.paymentForm}`} />);    
    }  
    return (
    <React.Fragment>
        
        <section class="mockPlans">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 my-auto text-center">
                        <h2 className="title">Mock Packages</h2>
                        <p></p>
                <div class="planOuter">
                    <div class="planHeader">

                        <h2><span> $ {this.state.price[this.state.slide][this.state.isVideo].price} / {this.state.price[this.state.slide][this.state.isVideo].duration}</span></h2>
                    </div>
                    <ul>
                        <li>
                            <div class="progressBar">
                                <input type="range" min="1" max="99" value={this.state.slide} onChange={this.handleChange} step="49" class="rangeSlider"  id="rangeSlider"  />
                            </div>
                            <div class="progressNo">
                                5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span></span>
                                
                                <span>10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;30
                            </div>
                        </li>
                        <li><i class="fa fa-check" aria-hidden="true"></i> <span>{this.state.mockCount[this.state.slide]} AI Scored Full Mock Test</span></li>
                        <li><i class="fa fa-check" aria-hidden="true"></i> <span>{this.state.mockCount[this.state.slide]*4} AI Scored Sectional Mock Test</span></li>
                        <li><i class="fa fa-check" aria-hidden="true"></i> <span>Feedback for every question</span></li>
                        <li><i class="fa fa-check" aria-hidden="true"></i> <span>In-Depth Analysis for every question you attempt</span></li>
                        <li><i class="fa fa-check" aria-hidden="true"></i> <span>Improvement suggestions</span></li>
                        <li><i class="fa fa-check" aria-hidden="true"></i> <span>Section-wise analysis</span></li>
                        <li><i class="fa fa-check" aria-hidden="true"></i> <span>Progress Tracker to help you monitor your performance</span></li>
                        <li><i class="fa fa-check" aria-hidden="true"></i> <span>Access to weekly prediction files</span></li>
                        <li><i class="fa fa-check" aria-hidden="true"></i> <span>Access to proven templates</span></li>
                        <li><button onClick={this.buyPlan} class="themeBtn">Buy Now</button><br/>
                            Do you want to add video course too <a onClick={(e)=>this.changeVideo(1)} className={(this.state.isVideo === 1)?"grnbtn":""}>Yes</a>  <a onClick={(e)=>this.changeVideo(0)} className={(this.state.isVideo ===0)?"grnbtn":""}>No</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
    </React.Fragment>);
    }
}

export default MockPackage;