import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import Speech from "speak-tts";
import { useDispatch } from "react-redux";
import { setAlert } from "action/index.js";
import API from "api";

export default function WordOptionsPopup({
  isOpen,
  word,
  wordOptionAction
}) {

  const [modalOpen, setModalOpen] = useState(false);
  const [addRemove, setAddRemove] = useState(null);

  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  /**
   * Modal Open and close state.
   */
  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);


  /**
   * OnLoad Check Word in User Dictionary.
   */
  useEffect(() => {
    checkWordStatus();
  }, []);


  const checkWordStatus = () => {
    API({
      url: `/checkWord/dictionary?word=${word}`,
      method: "GET"
    }).then((res) => {
      const { exists } = res.data;
      setAddRemove(!exists);
    }).catch(err => console.log(err));
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  const modalClosed = () => {
    wordOptionAction(false, ""); // mark status false of actual open/close status.
  }

  const pronunciation = () => {
    const speech = new Speech();
    speech.speak({
      text: word
    });
  }

  const addToDictionary = () => {
    let messageObject = {
      flag: true,
      type: 1
    };

    API({
      url: "/add/dictionary",
      method: "POST",
      data: { word }
    }).then((res) => {
      if (res.status === 201) {
        setAddRemove(false);
        messageObject.message = res.data.message;
      } else {
        messageObject.message = "Something went wrong.";
        messageObject.type = 2;
      }

      dispatch(
        setAlert(messageObject)
      );
    }).catch(err => console.log("error", err));
  }

  const removeFromDictionary = () => {
    let messageObject = {
      flag: true,
      type: 1
    };

    API({
      url: "/removeWord/dictionary",
      method: "delete",
      data: { word }
    }).then((res) => {
      if (res.status === 200) {
        setAddRemove(true);
        messageObject.message = res.data.message;
      } else {
        messageObject.message = "Something went wrong. Unable to remove from your dictionary.";
        messageObject.type = 2;
      }
      
      dispatch(
        setAlert(messageObject)
      );
    }).catch(err => console.log("error", err));
  }


  return (
    <Modal size="md" isOpen={modalOpen} onClosed={() => modalClosed()}>
      <ModalHeader
        style={{
          textTransform: "capitalize"
        }}
        className="mb-4"
      >
        {word}
        &nbsp;&nbsp;&nbsp;
        <i
          className="fa fa-bullhorn text-speak-icon"
          aria-hidden="true"
          onClick={() => pronunciation()}
        ></i>
      </ModalHeader>

      <ModalBody className="text-center">
        <div className="pull-left">
          {addRemove === true &&
            <Button
              size="sm"
              onClick={() => addToDictionary()}
            >Add to Dictionary</Button>
          }
          {addRemove === false &&
            <Button
              size="sm"
              onClick={() => removeFromDictionary()}
            >Remove From Dictionary</Button>
          }
          {addRemove === null &&
            <Button
              size="sm"
            >
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </Button>
          }
        </div>

        <div className="pull-right">
          <Button
            size="sm"
            onClick={() => closeModal()}
          >Close</Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
