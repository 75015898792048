import React from 'react';
import axiosInstance from 'api';
import { Row, Col, Button } from 'reactstrap';
import 'components/custom.css';
import { setAlert } from "action/index.js";
import { store, persistor } from "store/index";

class Dictionary extends React.Component {
  state = {
    words: [],
    message: ''
  };

  componentDidMount() {
    this.fetchAllWords();
  }

  fetchAllWords = async () => {
    try {
      const response = await axiosInstance.get('/dictionary');
      this.setState({
        words: response.data.words,
      });
    } catch (error) {
      console.error('Error fetching words:', error);
    }
  };

  handleDeleteWord = async (word) => {
    try {
      const response = await axiosInstance.delete('/removeWord/dictionary', {
        data: { word }
      });
      store.dispatch(
        setAlert({ flag: true, type: 1, message: response.data.message })
      );
      this.fetchAllWords(); // Refresh the word list
    } catch (error) {
      console.error('Error deleting word:', error);
      this.setState({
        message: 'An error occurred. Please try again.',
      });
    }
  };

  render() {
    const { words, message } = this.state;
    return (
      <div className="content">
        <h2>Your Words</h2>
        {message && <p>{message}</p>}
        <Row>
          <Col md="4"><strong>Word</strong></Col>
          <Col md="4"><strong>Status</strong></Col>
          <Col md="4"><strong>Action</strong></Col>
        </Row>
        {words.map((word) => (
          <Row key={word.word} className="mb-2">
            <Col md="4">{word.word}</Col>
            <Col md="4">{word.status === 1 ? 'Active' : 'Inactive'}</Col>
            <Col md="4">
              <Button color="danger" onClick={() => this.handleDeleteWord(word.word)}>
                Remove from Dictionary
              </Button>
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

export default Dictionary;
