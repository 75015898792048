import React, { useState, useEffect } from 'react';
import { Button, Progress } from 'reactstrap';
import Sound from "react-sound";
import { s3base } from 'api.js';

export default function Waiting({
	changeWaitStatus
}) {
	const WAITSECONDS = 35;

	const [seconds, setSeconds] = useState(WAITSECONDS);
	const [intervalId, setIntervalId] = useState(null);
	const [isBeep, setIsBeep] = useState(false);
	const [url, setUrl] = useState("");

	const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
	const bu = `${s3base}/ptedata/ptemedia/beep.mp3`;

	useEffect(() => {
		let allow_increase = true;
		const i = setInterval(() => {
			if (allow_increase) {
				setSeconds(seconds => (seconds - 1));
			}
		}, 1000);
		setIntervalId(i);

		return (() => {
			allow_increase = false;
		});
	}, []);

	const recordingStatus = () => {
		if (seconds === 0) {
			skip();
			return "";
		}
		return `Recording starts in ${seconds} seconds`;
	}

	const beepFinished = () => {
		changeWaitStatus();
	}

	const skip = () => {
		clearInterval(intervalId);
		setIsBeep(true);
		changeBeepPlayStatus();
	}

	const changeBeepPlayStatus = async () => {
		setPlayStatus(Sound.status.PLAYING);
	}
	
	return (
		<>
			{!isBeep && (
				<>
					<p>{recordingStatus()}</p>
					<div className="stop-btn">
						<Button
							color="success"
							onClick={skip}
							size="sm"
						>Skip</Button>
					</div>
				</>
			)}
			<Progress color="info" value={0} />

			<Sound
				url={bu}
				playStatus={playStatus}
				onFinishedPlaying={beepFinished}
				autoLoad={true}
			/>
		</>
	);
}